import React from "react";
import { Textarea } from "@/components/ui/textarea";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Loader2 } from "lucide-react";
import {
    BtnBold,
    BtnBulletList,
    BtnNumberedList,
    BtnItalic,
    BtnUnderline,
    Editor,
    EditorProvider,
    Toolbar,
} from "react-simple-wysiwyg";

import axios from "axios";
import useBusinessInformationData from "../../../../zustand/businessInformationData.js";
import { useToast } from "@/components/ui/use-toast";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

import {
    PencilSquareIcon,
    Bars3Icon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarTrigger,
} from "@/components/ui/menubar";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ContactForm from "../ContactForm";
import { Skeleton } from "../../../../@/components/ui/skeleton.jsx";

Yup.setLocale({
    mixed: {
        default: "Field is invalid",
        required: "This field is required",
    },
});

const MobileMenu = ({ setIndex, setShowAlert, setShowContactForm }) => {
    return (
        <Menubar className="flex sm:hidden">
            <MenubarMenu>
                <MenubarTrigger className="p-0 w-min flex sm:hidden">
                    <Bars3Icon className="color-black w0min h-8" />
                </MenubarTrigger>
                <MenubarContent>
                    <MenubarItem
                        onClick={() => {
                            setIndex(0);
                        }}
                    >
                        Company Information
                    </MenubarItem>
                    <MenubarItem
                        onClick={() => {
                            setIndex(1);
                        }}
                    >
                        Contact Information
                    </MenubarItem>
                    <MenubarItem
                        onClick={() => {
                            setIndex(2);
                        }}
                    >
                        Company Links
                    </MenubarItem>
                    <MenubarItem
                        onClick={() => {
                            setIndex(3);
                        }}
                    >
                        Team Information
                    </MenubarItem>
                    <MenubarItem
                        onClick={() => {
                            setIndex(4);
                        }}
                    >
                        Awards Information
                    </MenubarItem>
                    <MenubarSeparator /> <MenubarItem>Users</MenubarItem>
                    <MenubarSeparator />{" "}
                    <MenubarItem
                        className="text-sm"
                        onClick={() => {
                            setShowContactForm(true);
                        }}
                    >
                        Support
                        <span className="ml-2">
                            <EnvelopeIcon className="h-5" />
                        </span>
                    </MenubarItem>
                    <MenubarItem
                        onClick={() => {
                            setShowAlert(true);
                        }}
                    >
                        Sign out
                    </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
        </Menubar>
    );
};

const nameAndDescriptionSchema = Yup.object().shape({
    description: Yup.string()
        .max(1000, "Description cannot exceed 1000 characters")
        .required(),
    company_name: Yup.string()
        .required()
        .min(3, "Name must be at least 3 characters"),
});

const contactInformationSchema = Yup.object().shape({
    company_email: Yup.string()
        .email("Email Address is invalid")
        .required()
        .test("email-domain", "Email domain is not allowed", function (value) {
            // Regular expression to match specified email domains
            const forbiddenDomainsRegex =
                /@(gmail\.com|outlook\.com|hotmail\.com|yahoo\.com|aol\.com|icloud\.com|live\.com)$/;
            return !forbiddenDomainsRegex.test(value);
        }),
    company_phone: Yup.string()
        .required()
        .matches(/^[\d+]+$/, "Phone can only contain numbers and the + sign")
        .test("valid-plus-sign", "Invalid use of + sign", (value) => {
            if (!value) return true;
            return !/^\++$/.test(value);
        })
        .matches(
            /^(\+\d+)?\d+$/,
            "Phone can only contain numbers and the + sign at the beginning"
        ),

    company_address: Yup.string()
        .required()
        .min(3, "Name must be at least 3 characters"),
});

const externalLinksSchema = Yup.object().shape({
    company_website: Yup.string()
        .required()
        .matches(/^(?!https:\/\/\s*$).+/, "Website is invalid")
        .matches(/^(?!https:\/\/\s).+/, "Website is invalid"),
    facebook: Yup.string()
        .nullable()
        .test("valid-url", "Invalid Facebook URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?facebook.com\/\S*$/.test(value)
            );
        }),
    instagram: Yup.string()
        .nullable()
        .test("valid-url", "Invalid Instagram URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?instagram.com\/\S*$/.test(value)
            );
        }),
    linkedin: Yup.string()
        .nullable()
        .test("valid-url", "Invalid LinkedIn URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?linkedin.com\/\S*$/.test(value)
            );
        }),
    youtube: Yup.string()
        .nullable()
        .test("valid-url", "Invalid YouTube URL", function (value) {
            return (
                !value || /^(https?:\/\/)?(www\.)?youtube.com\/\S*$/.test(value)
            );
        }),
    twitter: Yup.string()
        .nullable()
        .test("valid-url", "Invalid Twitter URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?twitter.com\/\S*$/.test(value) ||
                /^(https?:\/\/)?(www\.)?x.com\/\S*$/.test(value)
            );
        }),
});

const teamsSchema = Yup.object().shape({
    team_country_id: Yup.string().required(),
    gender: Yup.string().required(),
    name: Yup.string()
        .required()
        .matches(/^\S.*\S$/, "Name cannot be blank"),
    title: Yup.string()
        .trim()
        .required()
        .matches(/^\S.*\S$/, "Position cannot be blank"),
    team_image: Yup.mixed()
        .required()
        .test("file", "Invalid file format", (value) => {
            return value && value instanceof File;
        }),
});

const awardsSchema = Yup.object().shape({});

const MyAccount = () => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const setUserData = useBusinessInformationData(
        (state) => state.setFormData
    );
    const [showAlert, setShowAlert] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showContactForm, setShowContactForm] = React.useState(false);
    const { toast } = useToast();

    const schemas = [
        nameAndDescriptionSchema,
        contactInformationSchema,
        externalLinksSchema,
        teamsSchema,
        awardsSchema,
    ];

    const userData = useBusinessInformationData((state) => state.formData);
    const {
        register,
        handleSubmit,
        clearErrors,
        watch,
        formState: { errors },
        setValue,
        setError,
        reset,
    } = useForm({
        resolver: yupResolver(schemas[tabIndex]),
    });

    console.log(errors);

    const saveNameAndDescription = async (data) => {
        console.log("saving");
        console.log(data);
        setIsLoading(true);
        const formData = {
            ...data,
            company_address: userData.company_address,
            company_phone: userData?.company_phone,
            company_email: userData?.company_email,
            company_website: userData?.company_website,
        };
        try {
            const res = await axios.post("/save-user-type-data", formData);
            if (res.status === 200) {
                toast({
                    title: "Saved! ✅",
                });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const saveContactInformation = async (data) => {
        console.log(data);
        const formData = {
            ...data,
            company_website: userData?.company_website,
        };
        setIsLoading(true);
        try {
            const res = await axios.post("/save-user-type-data", formData);
            if (res.status === 200) {
                toast({
                    title: "Saved! ✅",
                });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const saveExternalLinks = async (data) => {
        setIsLoading(true);
        try {
            const res = await axios.post("/save-user-type-data", data);
            if (res.status === 200) {
                toast({
                    title: "Saved! ✅",
                });
            }
            setUserData();
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        const fetchFormData = async () => {
            setIsLoading(true);
            try {
                const res = await axios.get("/onboarding-data");
                if (res.data.data !== null) {
                    const {
                        address,
                        uae_business,
                        country_id,
                        city_id,
                        ...otherFields
                    } = res.data.data;
                    const parsedAddress = JSON.parse(address);
                    setUserData({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id,
                        city_id: city_id,
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });
                    console.log(res.data.data);
                    reset({
                        company_name: res.data.data?.company_name,
                        description: res.data.data?.description,
                    });
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        fetchFormData();
    }, []);

    const signOut = async () => {
        try {
            const res = await axios.post("/logout");
            console.log(res);
            if (res.status === 200) {
                window.location.href = "/login";
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="min-h-screen pt-10 sm:px-8 sm:pt-16 lg:pt-0 overflow-x-clip overflow-y-auto w-full pb-8 flex items-start justify-center bg-[#F7F7F8]">
            <ContactForm
                showContactForm={showContactForm}
                setShowContactForm={setShowContactForm}
            />
            <AlertDialog
                open={showAlert}
                onOpenChange={(val) => {
                    setShowAlert(val);
                }}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle className="flex text-left">
                            Are your sure you want to sign out?
                        </AlertDialogTitle>
                    </AlertDialogHeader>
                    <AlertDialogFooter className="flex flex-row items-center justify-end w-full">
                        <AlertDialogCancel className="m-0 mr-2">
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            className="m-0"
                            onClick={() => signOut()}
                        >
                            Yes
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <div className=" hidden items-start sm:flex sm:flex-col mt-8 space-y-2 pl-2 sm:w-[300px]">
                <h1 className="justify-center text-3xl hidden sm:flex">
                    My Account
                </h1>
                <Button
                    type="button"
                    onClick={() => {
                        setTabIndex(0);
                    }}
                    variant="ghost"
                    className={cn(
                        " text-lg",
                        tabIndex == 0 && "font-bold text-black"
                    )}
                >
                    Company Informations
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        setTabIndex(1);
                    }}
                    variant="ghost"
                    className={cn(
                        " text-lg",
                        tabIndex == 1 && "font-bold text-black"
                    )}
                >
                    Contact Information
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        setTabIndex(2);
                    }}
                    variant="ghost"
                    className={cn(
                        " text-lg",
                        tabIndex == 2 && "font-bold text-black"
                    )}
                >
                    Company Links
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        setTabIndex(3);
                    }}
                    variant="ghost"
                    className={cn(
                        " text-lg",
                        tabIndex == 3 && "font-bold text-black"
                    )}
                >
                    Team Information
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        setTabIndex(4);
                    }}
                    variant="ghost"
                    className={cn(
                        " text-lg",
                        tabIndex == 4 && "font-bold text-black"
                    )}
                >
                    Awards Information
                </Button>
                <Separator className="w-44 ml-4" />
                <Button variant="ghost" className="text-lg mt-12">
                    Users
                </Button>
                <Separator className="w-44 ml-4" />
                <Button
                    type="button"
                    variant="ghost"
                    className="text-lg mt-12"
                    onClick={() => {
                        window.location.pathname = "/en/ticket";
                    }}
                >
                    Support
                    <span className="ml-2">
                        <EnvelopeIcon className="h-5" />
                    </span>
                </Button>
                <Button
                    type="button"
                    variant="ghost"
                    className="text-lg mt-12"
                    onClick={() => {
                        setShowAlert(true);
                    }}
                >
                    Sign out
                </Button>
            </div>
            {tabIndex == 0 && (
                <form
                    id="name-description-form"
                    onSubmit={handleSubmit(saveNameAndDescription)}
                    className="sm:mt-8 h-full w-[390px] sm:w-[814px]"
                >
                    <h1 className="flex items-center justify-between mx-2 mt-16 sm:mt-0 text-xl sm:ml-0 sm:text-4xl mb-4">
                        Company Information
                        <span>
                            <MobileMenu
                                setIndex={setTabIndex}
                                setShowAlert={setShowAlert}
                                setShowContactForm={setShowContactForm}
                            />
                        </span>
                    </h1>
                    <div className="space-y-4 md:mx-0 w-full">
                        <LogoForm isFetching={isLoading} />
                        <BannerForm isFetching={isLoading} />
                        <WysWyg
                            register={register}
                            setValue={setValue}
                            isLoading={isLoading}
                            reset={reset}
                            errors={errors}
                            watch={watch}
                        />
                        <UploadCompanyLicense isFetching={isLoading} />
                    </div>
                </form>
            )}
            {tabIndex == 1 && (
                <form
                    id="contact-information-form"
                    onSubmit={handleSubmit(saveContactInformation)}
                    className="sm:mt-8 h-full w-[390px] sm:w-[814px]"
                >
                    <div className="w-full">
                        <h1 className="flex items-center justify-between mx-2 mt-16 sm:mt-0 text-xl sm:ml-0 sm:text-4xl mb-4">
                            Contact Information
                            <span>
                                <MobileMenu
                                    setIndex={setTabIndex}
                                    setShowAlert={setShowAlert}
                                    setShowContactForm={setShowContactForm}
                                />
                            </span>
                        </h1>
                        <section className="flex flex-col space-y-4 p-6 bg-white rounded-2xl max-w-[814px] max-md:px-5">
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Email
                                </h2>
                                <Input
                                    defaultValue={userData?.company_email}
                                    placeholder="Enter email address"
                                    {...register("company_email")}
                                    required
                                />
                                {errors?.company_email && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.company_email?.message}
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Phone
                                </h2>
                                <Input
                                    defaultValue={userData?.company_phone}
                                    placeholder="Phone number"
                                    {...register("company_phone")}
                                    required
                                />
                                {errors?.company_phone && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.company_phone?.message}
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Address
                                </h2>
                                <Input
                                    defaultValue={userData?.company_address}
                                    placeholder="Company address"
                                    {...register("company_address")}
                                    required
                                />
                                {errors?.company_address && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.company_address?.message}
                                    </p>
                                )}
                            </div>
                            <div className="flex gap-2 items-start self-stretch text-sm leading-5 whitespace-nowrap max-md:flex-wrap">
                                <Button
                                    key={1}
                                    className="text-white bg-green-700 rounded-full"
                                    type="submit"
                                    form="contact-information-form"
                                >
                                    Save
                                </Button>
                                <Button
                                    key={2}
                                    className="bg-white rounded-full text-rose-500 border border-rose-500 border-solid"
                                    type="button"
                                    onClick={() => {
                                        reset({
                                            company_email: "",
                                            company_phone: "",
                                            company_address: "",
                                        });
                                    }}
                                >
                                    Reset
                                </Button>
                            </div>
                        </section>
                    </div>
                </form>
            )}
            {tabIndex == 2 && (
                <form
                    id="external-links-form"
                    onSubmit={handleSubmit(saveExternalLinks)}
                    className="sm:mt-8 h-full w-[390px] sm:w-[814px]"
                >
                    <div className="w-full">
                        <h1 className="flex items-center justify-between mx-2 mt-16 sm:mt-0 text-xl sm:ml-0 sm:text-4xl mb-4">
                            Company Links
                            <span>
                                <MobileMenu
                                    setIndex={setTabIndex}
                                    setShowAlert={setShowAlert}
                                    setShowContactForm={setShowContactForm}
                                />
                            </span>
                        </h1>
                        <section className="flex flex-col space-y-4 p-6 bg-white rounded-2xl max-w-[814px] max-md:px-5 ">
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Website
                                </h2>
                                <Input
                                    defaultValue={userData?.company_website}
                                    placeholder="https://start.org.ae/"
                                    {...register("company_website")}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        if (e.target.value == "https://") {
                                            setValue("company_website", "");
                                            return;
                                        }
                                        if (
                                            !e.target.value.startsWith(
                                                "https://"
                                            )
                                        ) {
                                            setValue(
                                                "company_website",
                                                "https://" + e.target.value
                                            );
                                        } else {
                                            setValue(
                                                "company_website",
                                                e.target.value
                                            );
                                        }
                                        trigger("company_website");
                                    }}
                                />
                            </div>
                            {errors?.company_website && (
                                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                    {errors.company_website?.message}
                                </p>
                            )}
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    LinkedIn
                                </h2>
                                <Input
                                    defaultValue={userData?.linkedin}
                                    placeholder="Add LinkedIn Link"
                                    {...register("linkedin")}
                                />
                                {errors.linkedin && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.linkedin?.message}
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Facebook
                                </h2>
                                <Input
                                    defaultValue={userData?.facebook}
                                    placeholder="Add Facebook Link"
                                    {...register("facebook")}
                                />
                                {errors.facebook && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.facebook?.message}
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    YouTube
                                </h2>
                                <Input
                                    defaultValue={userData?.youtube}
                                    placeholder="Add YouTube Link"
                                    {...register("youtube")}
                                />
                                {errors.youtube && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.youtube?.message}
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Instagram
                                </h2>
                                <Input
                                    defaultValue={userData?.instagram}
                                    placeholder="Add Instagram Link"
                                    {...register("instagram")}
                                />
                                {errors.instagram && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.instagram?.message}
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                                    Twitter
                                </h2>
                                <Input
                                    defaultValue={userData?.twitter}
                                    placeholder="Add Twitter Link"
                                    {...register("twitter")}
                                />
                                {errors.twitter && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                        {errors.twitter?.message}
                                    </p>
                                )}
                            </div>
                            <div className="flex gap-2 items-start self-stretch text-sm leading-5 whitespace-nowrap max-md:flex-wrap">
                                <Button
                                    key={1}
                                    className="text-white bg-green-700 rounded-full"
                                    form="external-links-form"
                                >
                                    Save
                                </Button>
                                <Button
                                    type="button"
                                    key={2}
                                    className="bg-white rounded-full text-rose-500 border border-rose-500 border-solid"
                                    onClick={() => {
                                        reset({
                                            company_website: "",
                                            linkedin: "",
                                            facebook: "",
                                            youtube: "",
                                            instagram: "",
                                            twitter: "",
                                        });
                                    }}
                                >
                                    Reset
                                </Button>
                            </div>
                        </section>
                    </div>
                </form>
            )}
            {tabIndex == 3 && (
                <div className="sm:mt-8 h-full w-[390px] sm:w-[814px]">
                    <h1 className="flex items-center justify-between mx-2 mt-16 sm:mt-0 text-xl sm:ml-0 sm:text-4xl mb-4">
                        Team Information
                        <span>
                            <MobileMenu
                                setIndex={setTabIndex}
                                setShowAlert={setShowAlert}
                                setShowContactForm={setShowContactForm}
                            />
                        </span>
                    </h1>
                    <TeamsInformation />
                </div>
            )}
            {tabIndex == 4 && (
                <div className="sm:mt-8 h-full w-[390px] sm:w-[814px]">
                    <h1 className="flex items-center justify-between mx-2 mt-16 sm:mt-0 text-xl sm:ml-0 sm:text-4xl mb-4">
                        Awards Information
                        <span>
                            <MobileMenu
                                setIndex={setTabIndex}
                                setShowAlert={setShowAlert}
                                setShowContactForm={setShowContactForm}
                            />
                        </span>
                    </h1>
                    <AwardsInformation />
                </div>
            )}
        </div>
    );
};

export default MyAccount;

function CompanyLogo({ isLoading, logoType, logoSrc, logoAlt }) {
    return (
        <div className="flex gap-5 p-6 leading-[150%] max-md:flex-wrap max-md:px-5">
            <div className="flex flex-col flex-1 my-auto max-md:max-w-full">
                {isLoading ? (
                    <Skeleton className={"h-6 w-32"} />
                ) : (
                    <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                        Company Logo
                    </h2>
                )}
                {isLoading ? (
                    <Skeleton className={"h-4 w-32 mt-2"} />
                ) : (
                    <p className="m-0 mt-1.5 text-sm text-zinc-500 max-md:max-w-full">
                        600px x 600px
                    </p>
                )}
            </div>
            {isLoading ? (
                <Skeleton
                    className={cn(
                        "object-cover",
                        logoType == "banner"
                            ? "rounded-none w-[200px] h-[64px]"
                            : "rounded-full w-16 h-16 border-black border"
                    )}
                />
            ) : (
                <img
                    src={logoSrc}
                    alt={logoAlt}
                    className={cn(
                        "object-cover",
                        logoType == "banner"
                            ? "rounded-none w-[200px] h-[64px]"
                            : "rounded-full w-16 h-16 border-black border"
                    )}
                />
            )}
        </div>
    );
}

function BannerLogo({ isLoading, logoSrc, logoAlt }) {
    return (
        <div className="flex gap-5 p-6 leading-[150%] max-md:flex-wrap max-md:px-5">
            <div className="flex flex-col flex-1 my-auto max-md:max-w-full">
                {isLoading ? (
                    <Skeleton className={"h-6 w-32"} />
                ) : (
                    <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                        Company banner
                    </h2>
                )}
                {isLoading ? (
                    <Skeleton className={"h-4 w-32 mt-2"} />
                ) : (
                    <p className="m-0 mt-1.5 text-sm text-zinc-500 max-md:max-w-full">
                        1920px x 600px
                    </p>
                )}
            </div>
            {isLoading ? (
                <Skeleton
                    className={cn(
                        "object-cover",
                        "rounded-none w-[200px] h-[64px]"
                    )}
                />
            ) : (
                <img
                    src={logoSrc}
                    alt={logoAlt}
                    className={cn(
                        "object-cover",
                        "rounded-none w-[200px] h-[64px]"
                    )}
                />
            )}
        </div>
    );
}

function LogoDetails({ isLoading, fileName, src, onClick }) {
    return (
        <div className="flex justify-between gap-2 p-4 whitespace-nowrap bg-gray-50 rounded-2xl border border-gray-300 border-solid max-md:flex-wrap">
            {isLoading ? (
                <Skeleton className="shrink-0 object-cover self-start aspect-square w-[42px]" />
            ) : (
                <img
                    src={src}
                    alt={`${fileName} file type`}
                    className="shrink-0 object-cover self-start aspect-square w-[42px]"
                />
            )}
            {/* <div className="flex flex-col flex-1 justify-center my-auto max-md:max-w-full">
                <p className="text-sm leading-5 text-zinc-800 max-md:max-w-full m-0">
                    {fileName}
                </p>
                <p className="text-xs leading-5 text-zinc-500 max-md:max-w-full m-0">
                    {fileSize} Kb
                </p>
            </div> */}
            {isLoading ? (
                <Skeleton className={"h-8 w-8"} />
            ) : (
                <button disabled={isLoading} type="button" onClick={onClick}>
                    <PencilSquareIcon className="h-8 text-blue-500" />
                </button>
            )}
        </div>
    );
}

function BannerDetails({
    isLoading,
    fileName,
    src,
    fileSize,
    fileType,
    onClick,
}) {
    return (
        <div className="flex justify-between gap-2 p-4 whitespace-nowrap bg-gray-50 rounded-2xl border border-gray-300 border-solid max-md:flex-wrap">
            {isLoading ? (
                <Skeleton className="shrink-0 object-cover self-start aspect-square w-[42px]" />
            ) : (
                <img
                    src={src}
                    alt={`${fileName} file type`}
                    className="shrink-0 object-cover self-start aspect-square w-[42px]"
                />
            )}
            {/* <div className="flex flex-col flex-1 justify-center my-auto max-md:max-w-full">
                <p className="text-sm leading-5 text-zinc-800 max-md:max-w-full m-0">
                    {fileName}
                </p>
                <p className="text-xs leading-5 text-zinc-500 max-md:max-w-full m-0">
                    {fileSize} Kb
                </p>
            </div> */}
            {isLoading ? (
                <Skeleton className={"h-8 w-8"} />
            ) : (
                <button disabled={isLoading} type="button" onClick={onClick}>
                    <PencilSquareIcon className="h-8 text-blue-500" />
                </button>
            )}
        </div>
    );
}

function BannerForm({ isFetching }) {
    const [isLoading, setIsLoading] = React.useState(isFetching);
    const [error, setError] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const userData = useBusinessInformationData((state) => state.formData);
    const setUserData = useBusinessInformationData(
        (state) => state.setFormData
    );
    const [shouldRefresh, setShouldRefresh] = React.useState(false);

    React.useEffect(() => {
        const fetchFormData = async () => {
            console.log("FETCHING");
            try {
                const res = await axios.get("/onboarding-data");
                if (res.data.data !== null) {
                    const {
                        address,
                        uae_business,
                        country_id,
                        city_id,
                        ...otherFields
                    } = res.data.data;
                    const parsedAddress = JSON.parse(address);
                    setUserData({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id,
                        city_id: city_id,
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (shouldRefresh) {
            fetchFormData();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    const logoData = {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d2c75c17280586b55f47b2bf829ac885041494e5804673166281222a1d7946d4?apiKey=2863e55464904361bde551202a8063a4&",
        alt: "Company logo",
    };

    const { toast } = useToast();
    const fileData = [
        {
            name: "Snuddy_Logo.jpg",
            size: "12kb",
            type: "https://cdn.builder.io/api/v1/image/assets/TEMP/137a712ccdd0ce8851bda5cfa00119ccf0d894e2e102f5f9f8683ee32db3b13e?apiKey=2863e55464904361bde551202a8063a4&",
        },
    ];

    const saveLogo = async (file) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("banner", file);
        formData.append("company_address", userData.company_address);
        formData.append("company_phone", userData.company_phone);
        formData.append("company_email", userData.company_email);
        formData.append("company_website", userData.company_website);
        try {
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (res.status === 200) {
                setShouldRefresh(true);
                toast({
                    description: "Banner updated",
                });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size > 10485760) {
                setError("File size too large");
            } else if (
                !(
                    file?.type.startsWith("image/") &&
                    /\.(jpg|jpeg|png)$/i.test(file.name)
                )
            ) {
                setError(
                    "Invalid file format. Please upload a JPG, JPEG, PNG, or GIF image."
                );
            } else {
                console.log("saving");
                saveLogo(file);
            }
        }
    };

    return (
        <section className="flex flex-col self-stretch pb-6 bg-white rounded-2xl max-w-[450px] md:max-w-[814px]">
            <BannerLogo
                isLoading={isFetching || isLoading}
                logoSrc={userData?.banner ?? logoData.src}
                logoAlt={logoData.alt}
            />
            <div className="flex flex-col px-6 w-full max-md:px-5 max-md:max-w-full">
                {fileData.map((file, index) => (
                    <BannerDetails
                        isLoading={isLoading || isFetching}
                        key={index}
                        fileName={userData?.banner_name ?? file.name}
                        fileSize={userData?.banner_size / 1024 ?? file.size}
                        src={userData?.banner ?? logoData.src}
                        fileType={"banner"}
                        onClick={handleButtonClick}
                    />
                ))}{" "}
                {error && (
                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                        {error}
                    </p>
                )}
                <input
                    className="hidden"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                {isLoading || isFetching ? (
                    <Skeleton className={"h-8 w-32 mt-4 rounded-[45px]"} />
                ) : (
                    <button
                        disabled={isFetching || isLoading}
                        type="button"
                        className="justify-center self-start px-4 py-2 mt-6 text-sm leading-5 text-white bg-green-700 rounded-[45px]"
                        onClick={handleButtonClick}
                    >
                        Update Banner
                    </button>
                )}
            </div>
        </section>
    );
}

function LogoForm({ isFetching }) {
    const [isLoading, setIsLoading] = React.useState(isFetching);
    const [error, setError] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const userData = useBusinessInformationData((state) => state.formData);
    const setUserData = useBusinessInformationData(
        (state) => state.setFormData
    );
    const [shouldRefresh, setShouldRefresh] = React.useState(false);

    React.useEffect(() => {
        const fetchFormData = async () => {
            console.log("FETCHING");
            setIsLoading(true);
            try {
                const res = await axios.get("/onboarding-data");
                if (res.data.data !== null) {
                    const {
                        address,
                        uae_business,
                        country_id,
                        city_id,
                        ...otherFields
                    } = res.data.data;
                    const parsedAddress = JSON.parse(address);
                    setUserData({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id,
                        city_id: city_id,
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        if (shouldRefresh) {
            fetchFormData();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    const logoData = {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d2c75c17280586b55f47b2bf829ac885041494e5804673166281222a1d7946d4?apiKey=2863e55464904361bde551202a8063a4&",
        alt: "Company logo",
    };

    const { toast } = useToast();
    const fileData = [
        {
            name: "Snuddy_Logo.jpg",
            size: "12kb",
            type: "https://cdn.builder.io/api/v1/image/assets/TEMP/137a712ccdd0ce8851bda5cfa00119ccf0d894e2e102f5f9f8683ee32db3b13e?apiKey=2863e55464904361bde551202a8063a4&",
        },
    ];

    const saveLogo = async (file) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("logo", file);
        formData.append("company_address", userData.company_address);
        formData.append("company_phone", userData.company_phone);
        formData.append("company_email", userData.company_email);
        formData.append("company_website", userData.company_website);
        try {
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setShouldRefresh(true);
            console.log(res);
            if (res.status === 200) {
                toast({
                    description: "Logo updated",
                });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size > 10485760) {
                setError("File size too large");
            } else if (
                !(
                    file?.type.startsWith("image/") &&
                    /\.(jpg|jpeg|png)$/i.test(file.name)
                )
            ) {
                setError(
                    "Invalid file format. Please upload a JPG, JPEG, PNG, or GIF image."
                );
            } else {
                console.log("saving");
                saveLogo(file);
            }
        }
    };

    return (
        <section className="flex flex-col self-stretch pb-6 bg-white rounded-2xl max-w-[451px] md:max-w-[814px]">
            <CompanyLogo
                isLoading={isFetching || isLoading}
                logoType={"logo"}
                logoSrc={userData?.logo ?? logoData.src}
                logoAlt={logoData.alt}
            />
            <div className="flex flex-col px-6 w-full max-md:px-5 max-md:max-w-full">
                {fileData.map((file, index) => (
                    <LogoDetails
                        isLoading={isFetching || isLoading}
                        key={index}
                        fileName={userData?.logo_name ?? file.name}
                        fileSize={userData?.logo_size / 1024 ?? file.size}
                        src={userData?.logo ?? logoData.src}
                        fileType={"logo"}
                        onClick={handleButtonClick}
                    />
                ))}{" "}
                {error && (
                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                        {error}
                    </p>
                )}
                <input
                    className="hidden"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                {isLoading || isFetching ? (
                    <Skeleton className={"h-8 w-32 mt-4 rounded-[45px]"} />
                ) : (
                    <button
                        disabled={isLoading || isFetching}
                        type="button"
                        className="justify-center self-start px-4 py-2 mt-6 text-sm leading-5 text-white bg-green-700 rounded-[45px]"
                        onClick={handleButtonClick}
                    >
                        Update Logo
                    </button>
                )}
            </div>
        </section>
    );
}

function FileUpload({ isLoading, fileIcon, onClick, src }) {
    console.log(src);
    return (
        <div className="flex justify-between gap-2 p-4 mt-6 whitespace-nowrap bg-gray-50 rounded-2xl border border-gray-300 border-solid max-md:flex-wrap">
            {isLoading ? (
                <Skeleton className="shrink-0 self-start aspect-square w-[42px]" />
            ) : (
                <img
                    src={fileIcon}
                    alt=""
                    className="shrink-0 self-start aspect-square w-[42px]"
                />
            )}
            <div className="flex space-x-2 justify-center my-auto max-md:max-w-full">
                {isLoading ? (
                    <Skeleton className={"h-8 w-8"} />
                ) : (
                    <a disabled={isLoading} href={src} target="__blank">
                        <ArrowDownTrayIcon className="h-8 text-green-500" />
                    </a>
                )}
                {isLoading ? (
                    <Skeleton className={"h-8 w-8"} />
                ) : (
                    <button
                        disabled={isLoading}
                        type="button"
                        onClick={onClick}
                    >
                        <PencilSquareIcon className="h-8 text-blue-500" />
                    </button>
                )}
            </div>
        </div>
    );
}

function UploadCompanyLicense({ isFetching }) {
    const [isLoading, setIsLoading] = React.useState(isFetching);
    const [error, setError] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const { toast } = useToast();

    const userData = useBusinessInformationData((state) => state.formData);
    const setUserData = useBusinessInformationData(
        (state) => state.setFormData
    );
    const [shouldRefresh, setShouldRefresh] = React.useState(false);

    React.useEffect(() => {
        const fetchFormData = async () => {
            console.log("FETCHING");
            try {
                const res = await axios.get("/onboarding-data");
                if (res.data.data !== null) {
                    const {
                        address,
                        uae_business,
                        country_id,
                        city_id,
                        ...otherFields
                    } = res.data.data;
                    const parsedAddress = JSON.parse(address);
                    setUserData({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id,
                        city_id: city_id,
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (shouldRefresh) {
            fetchFormData();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    const files = [
        {
            fileName: "Snuddy_License.pdf",
            fileSize: "12kb",
            fileIcon:
                "https://cdn.builder.io/api/v1/image/assets/TEMP/7948438aeb2f124004886e25aed5928fb6cc556a43a1f7046e946cafbc74acc7?apiKey=2863e55464904361bde551202a8063a4&",
        },
    ];

    const saveLicense = async (file) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("company_license", file);
        formData.append("company_address", userData.company_address);
        formData.append("company_phone", userData.company_phone);
        formData.append("company_email", userData.company_email);
        formData.append("company_website", userData.company_website);
        try {
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(res);
            if (res.status === 200) {
                setShouldRefresh(true);
                toast({
                    description: "Company license updated",
                });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size == 0) {
                console.log(file.size);
                setError("Upload your company license");
            } else if (file?.size > 10485760) {
                setError("File size too large");
            } else if (
                !(
                    file?.type === "application/pdf" &&
                    file.name.match(/\.(pdf)$/i)
                )
            ) {
                setError("Invalid file format");
            } else {
                saveLicense(file);
            }
        }
    };

    return (
        <section className="flex flex-col self-stretch p-6 bg-white rounded-2xl max-w-[451px] md:max-w-[814px] max-md:px-5">
            {isLoading || isFetching ? (
                <Skeleton className={"h-6 w-32"} />
            ) : (
                <h2 className="text-base font-semibold leading-6 text-gray-900 max-md:max-w-full">
                    Company License
                </h2>
            )}
            {files.map((file, index) => (
                <FileUpload
                    key={index}
                    isLoading={isLoading || isFetching}
                    src={userData?.company_license}
                    fileName={userData?.license_name ?? file.fileName}
                    fileSize={userData?.license_size / 1024 ?? file.fileSize}
                    fileIcon={file.fileIcon}
                    onClick={handleButtonClick}
                />
            ))}{" "}
            {error && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {error}
                </p>
            )}
            <input
                className="hidden"
                type="file"
                accept=".pdf"
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            {isLoading || isFetching ? (
                <Skeleton className={"h-8 w-32 mt-4 rounded-[45px]"} />
            ) : (
                <button
                    onClick={handleButtonClick}
                    type="button"
                    className="justify-center self-start px-4 py-2 mt-6 text-sm leading-5 text-white bg-green-700 rounded-[45px]"
                >
                    Update company license
                </button>
            )}
        </section>
    );
}

function WysWyg({ register, setValue, isLoading, reset, errors, watch }) {
    const userData = useBusinessInformationData((state) => state.formData);
    const [description, setDescription] = React.useState(
        userData?.description ?? " "
    );
    console.log(description);
    React.useEffect(() => {
        setDescription(userData.description ?? "");
        setValue("company_name", userData?.company_name ?? "");
    }, [userData]);

    return (
        <section className="flex flex-col space-y-4 self-stretch p-6 bg-white rounded-2xl max-w-[451px] md:max-w-[814px] max-md:px-5">
            <div className="space-y-2">
                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                    Company Name
                </h2>
                <Input
                    defaultValue={userData?.company_name}
                    placeholder="Company name"
                    {...register("company_name")}
                    required
                />
                {errors?.company_name && (
                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                        {errors.company_name?.message}
                    </p>
                )}
            </div>
            <div className="space-y-2">
                <h2 className="m-0 text-base font-semibold text-gray-900 max-md:max-w-full">
                    Tell us more about your company
                </h2>
                <EditorProvider>
                    <Editor
                        containerProps={{
                            style: {
                                minHeight: "250px",
                            },
                        }}
                        {...register("description")}
                        defaultValue={watch("description")}
                        value={watch("description")}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            setValue("description", e.target.value);
                        }}
                    >
                        <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <BtnUnderline />
                            <BtnBulletList />
                            <BtnNumberedList />
                        </Toolbar>
                    </Editor>
                </EditorProvider>
                {errors?.description && (
                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                        {errors.description?.message}
                    </p>
                )}
            </div>
            <div className="flex gap-2 items-start self-stretch text-sm leading-5 whitespace-nowrap max-md:flex-wrap">
                <Button
                    key={1}
                    className="text-white bg-green-700 rounded-full"
                    form="name-description-form"
                    disabled={isLoading}
                >
                    Save
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        reset({
                            company_name: "",
                            description: "",
                        });
                        setDescription("");
                    }}
                    key={2}
                    className="bg-white rounded-full text-rose-500 border border-rose-500 border-solid"
                >
                    Reset
                </Button>
            </div>
        </section>
    );
}

function TeamCard({
    nationality,
    gender,
    setIsEditing,
    name,
    id,
    title,
    setId,
    linkedin,
    photo,
    countries,
    reset,
}) {
    function removeBaseUrl(url, baseUrl) {
        // Check if the url starts with the base url
        if (url.startsWith(baseUrl)) {
            // Remove the base url from the url and return the modified url
            return url.substring(baseUrl.length);
        } else {
            // If the url doesn't start with the base url, return the original url
            return url;
        }
    }

    return (
        <div className="flex gap-5 p-4 bg-gray-50 rounded-2xl border border-gray-300 border-solid max-md:flex-wrap">
            <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div className="text-sm font-semibold leading-5 text-zinc-800">
                        <p className="w-max flex items-center">
                            {name}
                            <span className="pl-2">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setId(id);
                                        setIsEditing(true);
                                        console.log(nationality, gender);
                                        reset({
                                            title: title,
                                            name: name,
                                            linkedin: linkedin ?? "",
                                            team_country_id:
                                                nationality.toString(),
                                            gender: gender,
                                        });
                                    }}
                                >
                                    <PencilSquareIcon className="flex h-5 text-blue-500" />
                                </button>
                            </span>
                        </p>
                        {countries.length !== 0 && (
                            <p className="text-sm m-0">{gender}</p>
                        )}
                    </div>
                    <div className="text-sm font-semibold leading-5 text-zinc-800">
                        {title}
                    </div>
                    {/* <button
                        className={`justify-center px-2 my-auto text-xs font-medium leading-4 text-white uppercase whitespace-nowrap rounded-xl ${
                            isActive ? "bg-green-700" : "bg-red-500"
                        }`}
                    >
                        {isActive ? "Active" : "Inactive"}
                    </button> */}
                </div>
                <div className="flex gap-2 pr-20 mt-2 text-sm leading-5 text-zinc-800 max-md:flex-wrap max-md:pr-5">
                    <div className="justify-center p-0.5 whitespace-nowrap rounded bg-zinc-200">
                        {linkedin !== null && (
                            <a
                                href={
                                    linkedin.startsWith("https")
                                        ? linkedin
                                        : `https://www.linkedin.com/company/${linkedin}`
                                }
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 48 48"
                                >
                                    <path
                                        fill="#0288D1"
                                        d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                    ></path>
                                    <path
                                        fill="#FFF"
                                        d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                    ></path>
                                </svg>
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <img
                loading="lazy"
                src={removeBaseUrl(photo, process.env.MIX_BASE_URL)}
                alt={`${name} logo`}
                className="shrink-0 my-auto h-24"
            />
        </div>
    );
}

function TeamsInformation() {
    const [teamMembers, setTeamMembers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const { toast } = useToast();
    const [shouldRefresh, setShouldRefresh] = React.useState(false);
    const [fileUploadError, setFileUploadError] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const [isEditing, setIsEditing] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [showForm, setShowForm] = React.useState(false);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size == 0) {
                console.log(file.size);
                setFileUploadError("Upload your company license");
                setValue("team_image", null);
            } else if (file?.size > 10485760) {
                console.log(file.size);
                // setFileUploadError("File size too large");
                setValue("team_image", null);
            } else if (
                !(
                    (
                        file?.type.includes("image/") && // Check if the file type starts with 'image/'
                        /\.(jpg|jpeg|png)$/i.test(file.name)
                    ) // Check if the file extension is one of the specified image formats
                )
            ) {
                // setFileUploadError("Invalid file format");
                setValue("team_image", null);
            } else {
                setValue("team_image", file);
                trigger("team_image");
            }
        }
    };

    const stepFourSchema = Yup.object().shape({
        team_country_id: Yup.string().required(),
        gender: Yup.string().required(),
        name: Yup.string()
            .required()
            .matches(
                /^[a-zA-Z\s]*$/,
                "Name should only contain letters and spaces"
            )
            .matches(/^\S.*\S$/, "Name cannot start or end with whitespace"),
        title: Yup.string()
            .required()
            .matches(/^\S.*\S$/, "Position cannot start or end with whitespace")
            .matches(/\S/, "Title cannot consist only of whitespace"),
        team_image: isEditing
            ? Yup.mixed().nullable()
            : Yup.mixed()
                  .required()
                  .test("file", "Invalid file format", (value) => {
                      return value && value instanceof File;
                  }),
    });

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(stepFourSchema),
    });

    const [countries, setCountries] = React.useState([]);

    React.useEffect(() => {
        const getTeamMembers = async () => {
            try {
                const res = await axios.get("/team-members-data");
                setTeamMembers(res.data.teams);
            } catch (error) {
                console.log(error);
            }
        };
        const fetchCountries = async () => {
            try {
                const res = await axios.get("/onboarding-countries");
                setCountries(res.data.countries);
            } catch (error) {}
        };
        getTeamMembers();
        fetchCountries();
    }, []);

    React.useEffect(() => {
        const getTeamMembers = async () => {
            try {
                const res = await axios.get("/team-members-data");
                setTeamMembers(res.data.teams);
            } catch (error) {
                console.log(error);
            }
        };
        if (shouldRefresh) {
            getTeamMembers();
            setShouldRefresh(false);
            return;
        }
    }, [shouldRefresh]);

    const handleAddTeam = async (data) => {
        setIsLoading(true);
        const teamData = {
            title: data?.title,
            name: data?.name,
            linkedin: data?.linkedin,
            nationality: data?.team_country_id,
            gender: data?.gender,
            image: data?.team_image,
        };
        try {
            const res = await axios.post("/add-team", teamData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setShouldRefresh(true);
            toast({
                title: "Success!",
                description: "New team member added",
            });
            reset({
                title: "",
                name: "",
                linkedin: "",
                team_image: null,
                team_country_id: "",
                gender: "",
            });
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
        setShowForm(false);
    };

    const handleEditTeam = async (data) => {
        setIsLoading(true);
        const teamData = {
            title: data?.title,
            name: data?.name,
            linkedin: data?.linkedin,
            nationality: data?.team_country_id,
            gender: data?.gender,
            image: data?.team_image ?? null,
        };
        try {
            const res = await axios.post(`/update-team/${id}`, teamData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setShouldRefresh(true);
            toast({
                title: "Success! ✅",
                description: "Team member saved",
            });
            reset({
                title: "",
                name: "",
                linkedin: "",
                team_image: null,
                team_country_id: "",
                gender: "",
            });
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
        setShowForm(false);
        setIsEditing(false);
    };

    const onSubmit = (data) => {
        isEditing ? handleEditTeam(data) : handleAddTeam(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="team-form">
            <div className="flex flex-col self-stretch bg-white rounded-2xl w-full">
                <header className="flex gap-5 p-6 max-md:flex-wrap max-md:px-5">
                    <div className="flex flex-col flex-1 leading-[150%] max-md:max-w-full">
                        <h2 className="text-base font-semibold text-gray-900 max-md:max-w-full">
                            Teams
                        </h2>
                        <p className="mt-1.5 text-sm text-zinc-500 max-md:max-w-full">
                            View and manage your teams and team members
                        </p>
                    </div>
                    <Dialog
                        open={showForm || isEditing}
                        onOpenChange={(val) => {
                            setShowForm(val);
                            setIsEditing(false);
                        }}
                    >
                        <DialogTrigger asChild>
                            <Button
                                onClick={() => {
                                    setIsEditing(false);
                                    setShowForm(true);
                                    setValue("title", "");
                                    setValue("name", "");
                                    setValue("linkedin", "");
                                    setValue("team_country_id", "");
                                    setValue("gender", "");
                                }}
                                variant={"outline"}
                                className="flex gap-2 justify-center mt-4 px-4 py-2 text-base font-medium leading-6 border border-gray-400 border-solid rounded-[45px]"
                            >
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/54fc1e01636e7295a5352869767576129386d21f19d7b17803ead42955bf3f4f?apiKey=2863e55464904361bde551202a8063a4&"
                                    className="shrink-0 my-auto w-5 aspect-square"
                                />
                                <div>{"Add Team"}</div>
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle className="text-2xl">
                                    Team Details
                                </DialogTitle>
                            </DialogHeader>
                            <div>
                                <Input
                                    className="w-full"
                                    placeholder="Full Name"
                                    {...register("name")}
                                    required
                                />
                                {errors.name && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                        {errors.name?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <Input
                                    className="w-full"
                                    placeholder="Position"
                                    {...register("title")}
                                    required
                                />
                                {errors.title && (
                                    <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                        {errors.title?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                                    <div className="flex-1">
                                        <p className="m-0 text-black text-md">
                                            {!isEditing
                                                ? "Add Photo"
                                                : "Update Photo"}
                                        </p>
                                        <p className="m-0 text-sm">
                                            Recommended size: 600px x 600px
                                        </p>
                                        {watch("team_image") !== null &&
                                            watch("team_image") !==
                                                undefined && (
                                                <Button
                                                    type="button"
                                                    className=" hover:cursor-none m-0 p-0"
                                                    variant={"ghost"}
                                                >
                                                    <p className="text-black m-0 text-xs w-32 truncate">
                                                        {
                                                            watch("team_image")
                                                                ?.name
                                                        }
                                                    </p>
                                                </Button>
                                            )}
                                    </div>
                                    <Button
                                        className="bg-[#ACADBA] rounded-lg p-2"
                                        type="button"
                                        onClick={() => {
                                            handleButtonClick();
                                        }}
                                    >
                                        <ArrowUpTrayIcon className="w-6 text-white" />
                                    </Button>{" "}
                                    <input
                                        className="hidden"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                </div>{" "}
                                {fileUploadError && (
                                    <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                        {fileUploadError}
                                    </p>
                                )}
                                {fileUploadError == null &&
                                    errors.team_image && (
                                        <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                            {errors.team_image?.message}
                                        </p>
                                    )}
                            </div>
                            <Input
                                className="w-full"
                                placeholder="LinkedIn"
                                {...register("linkedin")}
                            />
                            <div>
                                <Select
                                    {...register("gender")}
                                    value={watch("gender")}
                                    onValueChange={(val) => {
                                        setValue("gender", val);
                                        trigger("gender");
                                    }}
                                >
                                    <SelectTrigger className="w-full rounded-full">
                                        <SelectValue placeholder="Gender" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="Male">
                                            Male
                                        </SelectItem>
                                        <SelectItem value="Female">
                                            Female
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                                {errors.gender && (
                                    <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                        {errors.gender?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <Select
                                    className="text-black"
                                    {...register("team_country_id")}
                                    value={watch("team_country_id")}
                                    onValueChange={(val) => {
                                        setValue(
                                            "team_country_id",
                                            val.toString()
                                        );
                                        trigger("team_country_id");
                                    }}
                                >
                                    <SelectTrigger className="w-full rounded-full">
                                        <SelectValue
                                            className="text-black"
                                            placeholder={"Select nationality"}
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectLabel>Country</SelectLabel>
                                            {countries.map((country, index) => {
                                                return (
                                                    <SelectItem
                                                        key={country.name.en}
                                                        value={country.id.toString()}
                                                        id={index}
                                                    >
                                                        {country.name.en}
                                                    </SelectItem>
                                                );
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                                {errors.team_country_id && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                        {errors.team_country_id?.message}
                                    </p>
                                )}
                            </div>
                            <Button
                                disabled={isLoading}
                                type="submit"
                                form="team-form"
                            >
                                {!isEditing ? "Add Team" : "Update Team"}
                                {isLoading && (
                                    <span className="ml-2">
                                        <Loader2 className="h-5 animate-spin" />
                                    </span>
                                )}{" "}
                            </Button>
                        </DialogContent>
                    </Dialog>
                </header>
                {teamMembers.length !== 0 && (
                    <main className="flex flex-col px-6 pb-6 w-full max-md:px-5 overflow-y-auto max-h-[500px] max-md:max-w-full space-y-4">
                        {teamMembers
                            .slice() // Create a shallow copy of the array to avoid mutating the original
                            .sort((a, b) => {
                                // Compare by name first
                                const nameComparison = a.name.localeCompare(
                                    b.name
                                );
                                if (nameComparison !== 0) return nameComparison;

                                // If names are equal, compare by title
                                return a.title.localeCompare(b.title);
                            })
                            .map((team, index) => {
                                console.log(team);
                                return (
                                    <TeamCard
                                        countries={countries}
                                        id={team.id}
                                        setId={setId}
                                        reset={reset}
                                        setIsEditing={setIsEditing}
                                        nationality={team.nationality}
                                        gender={team.gender}
                                        key={index}
                                        name={team.name}
                                        title={team.title}
                                        linkedin={team.linkedin}
                                        photo={team.image}
                                    />
                                );
                            })}
                    </main>
                )}
            </div>
        </form>
    );
}

function AwardCard({ award, setId, setIsEditing, reset }) {
    return (
        <div className="flex gap-5 p-4 w-full text-xs bg-gray-50 rounded-2xl border border-gray-300 border-solid max-w-[766px] text-zinc-500 max-md:flex-wrap max-md:max-w-full">
            {" "}
            <div className="flex flex-col flex-1 max-md:max-w-full">
                {" "}
                <div className="flex gap-4 pr-20 text-sm leading-5 text-zinc-800 max-md:flex-wrap max-md:pr-5">
                    {" "}
                    <div className="my-auto">
                        {award.title}
                        <span className="pl-2">
                            <button
                                type="button"
                                onClick={() => {
                                    setId(award.id);
                                    setIsEditing(true);
                                    reset({
                                        award_title: award.title,
                                        award_date: award.date,
                                        award_description: award.description,
                                    });
                                }}
                            >
                                <PencilSquareIcon className="flex h-5 text-blue-500" />
                            </button>
                        </span>
                    </div>{" "}
                </div>{" "}
                <p className="mt-2 leading-5 text-ellipsis max-md:max-w-full">
                    {award.date}{" "}
                </p>{" "}
                <p className="mt-2 leading-5 text-ellipsis max-md:max-w-full">
                    {award.description}{" "}
                </p>{" "}
            </div>{" "}
            {!award.image.endsWith("plana.jpeg") && (
                <img
                    loading="lazy"
                    src={award.image}
                    alt={`award`}
                    className="shrink-0 my-auto h-24"
                />
            )}
        </div>
    );
}
function AwardsInformation() {
    const [shouldRefresh, setShouldRefresh] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [awards, setAwards] = React.useState([]);
    const { toast } = useToast();

    const [fileUploadError, setFileUploadError] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const [isEditing, setIsEditing] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [id, setId] = React.useState(null);

    const handleAwardDateChange = (event) => {
        const enteredDate = new Date(event.target.value);
        const currentDate = new Date();
        if (enteredDate > currentDate) {
            // If the entered date is in the future, revert to the latest date
            setValue("award_date", currentDate.toISOString().slice(0, 10)); // Format date as 'YYYY-MM-DD'
        } else {
            setValue("award_date", event.target.value); // Set the entered date
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size == 0) {
                console.log(file.size);
                setFileUploadError("Upload your company license");
                setValue("award_image", null);
            } else if (file?.size > 10485760) {
                console.log(file.size);
                // setFileUploadError("File size too large");
                setValue("award_image", null);
            } else if (
                !(
                    (
                        file?.type.includes("image/") && // Check if the file type starts with 'image/'
                        /\.(jpg|jpeg|png)$/i.test(file.name)
                    ) // Check if the file extension is one of the specified image formats
                )
            ) {
                // setFileUploadError("Invalid file format");
                setValue("award_image", null);
            } else {
                setValue("award_image", file);
                trigger("award_image");
            }
        }
    };

    const saveAward = async (data) => {
        setIsLoading(true);
        const award = {
            title: data?.award_title,
            date: data?.award_date,
            description: data?.award_description,
            image: data?.award_image,
        };
        try {
            const res = await axios.post("/add-award", award, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setShouldRefresh(true);
            toast({
                title: "Success!",
                description: "Award saved",
            });
            reset({
                award_title: "",
                award_date: "",
                award_description: "",
                award_image: "",
            });
        } catch (error) {
            console.log(error);
        }
        setShowForm(false);
        setIsLoading(false);
    };

    const updateAward = async (data) => {
        setIsLoading(true);
        const award = {
            title: data?.award_title,
            date: data?.award_date,
            description: data?.award_description,
            image: data?.award_image,
        };
        try {
            const res = await axios.post("/update-award/" + id, award, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setShouldRefresh(true);
            toast({
                title: "Success!",
                description: "Award saved",
            });
            reset({
                award_title: "",
                award_date: "",
                award_description: "",
                award_image: "",
            });
        } catch (error) {
            console.log(error);
        }
        setShowForm(false);
        setIsLoading(false);
        setIsEditing(false);
    };

    React.useEffect(() => {
        const getAwards = async () => {
            try {
                const res = await axios.get("/awards-data");
                console.log(res);
                setAwards(res.data.awards);
            } catch (error) {
                console.log(error);
            }
        };
        getAwards();
    }, []);

    React.useEffect(() => {
        const getAwards = async () => {
            try {
                const res = await axios.get("/awards-data");
                console.log(res);
                setAwards(res.data.awards);
            } catch (error) {
                console.log(error);
            }
        };
        if (shouldRefresh) {
            getAwards();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    const onSubmit = (data) => {
        console.log(data);
        isEditing ? updateAward(data) : saveAward(data);
    };

    const maxDate = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }

        today = yyyy + "-" + mm + "-" + dd;
        return today;
    };

    const stepFiveSchema = Yup.object().shape({
        award_title: Yup.string().required(),
        award_date: Yup.string().required(),
        award_description: Yup.string().required(),
        // award_image: Yup.mixed().test("file", "Invalid file format", (value) => {
        //     return value && value instanceof File;
        // }),
    });

    const {
        register,
        handleSubmit,
        clearErrors,
        watch,
        trigger,
        reset,
        formState: { errors },
        setValue,
        setError,
        unregister,
    } = useForm({
        resolver: yupResolver(stepFiveSchema),
    });

    return (
        <form id="awards-form" onSubmit={handleSubmit(onSubmit)}>
            <section className="flex flex-col max-h-[700px] overflow-y-auto items-center self-stretch bg-white rounded-2xl max-w-[814px]">
                {" "}
                <header className="flex gap-5 self-stretch p-6 max-md:flex-wrap max-md:px-5">
                    {" "}
                    <div className="flex flex-col flex-1 leading-[150%] max-md:max-w-full">
                        {" "}
                        <h2 className="text-base font-semibold text-gray-900 max-md:max-w-full">
                            {" "}
                            Awards List{" "}
                        </h2>{" "}
                        <p className="mt-1.5 text-sm text-zinc-500 max-md:max-w-full">
                            {" "}
                            View and manage your awards{" "}
                        </p>{" "}
                    </div>{" "}
                    <Dialog
                        open={showForm || isEditing}
                        onOpenChange={(val) => {
                            setShowForm(val);
                            setIsEditing(false);
                        }}
                    >
                        <DialogTrigger asChild>
                            <Button
                                onClick={() => {
                                    setIsEditing(false);
                                    setShowForm(true);
                                    setValue("award_title", "");
                                    setValue("award_date", "");
                                    setValue("award_description", "");
                                }}
                                type="button"
                                variant={"outline"}
                                className="justify-center px-4 py-2 my-auto text-sm leading-5 text-white bg-green-700 hover:bg-green-700/90 rounded-[45px]"
                            >
                                Add Awards
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle className="text-2xl">
                                    Awards Details
                                </DialogTitle>
                            </DialogHeader>
                            <div>
                                <Input
                                    className="w-full"
                                    placeholder="Title"
                                    {...register("award_title")}
                                    required
                                />
                                {errors.award_title && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                        {errors.award_title?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <div className="flex space-x-2 w-full justify-start items-center">
                                    <p className="m-0">Award date:</p>
                                    <Input
                                        className="flex w-full"
                                        {...register("award_date")}
                                        type="date"
                                        max={maxDate()}
                                        required
                                        onChange={handleAwardDateChange}
                                    />
                                </div>
                                {errors.award_date && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1">
                                        {errors.award_date?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <Textarea
                                    className="w-full rounded-[18px]"
                                    placeholder="Description"
                                    {...register("award_description")}
                                    maxLength="255"
                                />
                                {errors.award_description && (
                                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                        {errors.award_description?.message}
                                    </p>
                                )}
                            </div>{" "}
                            <div>
                                <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                                    <div className="flex-1">
                                        <p className="m-0 text-black text-md">
                                            Add Photo
                                        </p>
                                        <p className="m-0 text-sm">
                                            Recommended size: 600px x 600px
                                        </p>
                                        {watch("award_image") !== null &&
                                            watch("award_image") !==
                                                undefined && (
                                                <Button
                                                    type="button"
                                                    className=" hover:cursor-none m-0 p-0"
                                                    variant={"ghost"}
                                                >
                                                    <p className="text-black m-0 text-xs w-32 truncate">
                                                        {
                                                            watch("award_image")
                                                                ?.name
                                                        }
                                                    </p>
                                                </Button>
                                            )}
                                    </div>
                                    <Button
                                        className="bg-[#ACADBA] rounded-lg p-2"
                                        type="button"
                                        onClick={() => {
                                            handleButtonClick();
                                        }}
                                    >
                                        <ArrowUpTrayIcon className="w-6 text-white" />
                                    </Button>{" "}
                                    <input
                                        className="hidden"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                </div>{" "}
                                {fileUploadError && (
                                    <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                        {fileUploadError}
                                    </p>
                                )}
                                {fileUploadError == null &&
                                    errors.award_image && (
                                        <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                            {errors.award_image?.message}
                                        </p>
                                    )}
                            </div>
                            <Button
                                // onClick={() => {
                                //     setIsDoneAddingTime((prev) => prev + 1);
                                // }}
                                disabled={isLoading}
                                type="submit"
                                form="awards-form"
                                className=" rounded-3xl mt-8 w-full"
                            >
                                {isEditing ? "Update Award" : "Add Award"}
                                {isLoading && (
                                    <span className="ml-2">
                                        <Loader2 className="h-5 animate-spin" />
                                    </span>
                                )}{" "}
                            </Button>
                        </DialogContent>
                    </Dialog>
                </header>{" "}
                {awards.length !== 0 && (
                    <section className="space-y-4 w-full mb-4 flex justify-center flex-col items-center">
                        {awards
                            .slice() // Create a shallow copy of the array to avoid mutating the original
                            .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
                            .map((award, index) => (
                                <AwardCard
                                    key={index}
                                    award={award}
                                    reset={reset}
                                    setId={setId}
                                    setIsEditing={setIsEditing}
                                />
                            ))}
                    </section>
                )}
            </section>
        </form>
    );
}
