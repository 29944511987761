import React from "react";
import logo from "../../../../website_theme/img/logo_white_sq.svg";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import ContactForm from "../components/ContactForm";

const Footer = () => {
    const currentPath = window.location.pathname;
    const isHomepageOrEn = currentPath === "/" || currentPath === "/en";
    const [showContactForm, setShowContactForm] = React.useState(false);

    const { toast } = useToast();

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const showTicketAccessError = queryParams.get("error");

        if (showTicketAccessError === "1") {
            toast({
                title: "You must login to view ticket details.",
                variant: "destructive",
            });
        }
    }, []);

    return (
        <footer className="py-8 h-full bg-gray-800 text-white flex items-center flex-col w-full justify-between">
            <Toaster />
            <div className="container mx-auto py-8 px-4">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                    <ContactForm
                        showContactForm={showContactForm}
                        setShowContactForm={setShowContactForm}
                    />
                    <div className="lg:col-span-1 flex flex-col items-center lg:items-end justify-center">
                        <a href="/" className="block">
                            <img src={logo} alt="Logo" className="h-24" />
                        </a>
                        {isHomepageOrEn && (
                            <p className="w-min text-center lg:text-right text-sm mt-3 bg-blue-800 rounded-lg p-2 text-white">
                                BETA
                            </p>
                        )}
                    </div>

                    <div className="lg:col-span-1">
                        <ul className="text-sm font-light space-y-4">
                            <p className="font-semibold pb-2">QUICK LINKS</p>
                            <li>
                                <a
                                    href="/"
                                    className="text-white no-underline hover:underline"
                                >
                                    Home
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/startups"
                                    className="text-white no-underline hover:underline"
                                >
                                    Start-ups
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/partners"
                                    className="text-white no-underline hover:underline"
                                >
                                    Partners
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/contact-us"
                                    className="text-white no-underline hover:underline"
                                >
                                    Contact us
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lg:col-span-1">
                        <ul className="text-sm font-light space-y-4">
                            <p className="font-semibold pb-2">
                                COMPANY UPDATES
                            </p>
                            <li>
                                <a
                                    href="/en/resources"
                                    className="text-white no-underline hover:underline"
                                >
                                    Resources
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/news"
                                    className="text-white no-underline hover:underline"
                                >
                                    News & Events
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lg:col-span-1">
                        <ul className="text-sm font-light space-y-4">
                            <p className="font-semibold pb-2">HELP</p>
                            <li>
                                <a
                                    href="/privacy"
                                    className="text-white no-underline hover:underline"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms"
                                    className="text-white no-underline hover:underline"
                                >
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <button
                                    className="text-white no-underline hover:underline"
                                    onClick={() => setShowContactForm(true)}
                                >
                                    Contact Us
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mt-8 text-center flex flex-col h-full justify-end items-center">
                <p className="text-gray-600 text-sm">
                    &copy; {new Date().getFullYear()} Company. All rights
                    reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
