import React from "react";
import logo from "../../../../website_theme/img/Layer_1-2.png";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import useAuthProcess from "../../../zustand/authProcessIndex.js";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";
import useBusinessInformationData from "../../../zustand/businessInformationData.js";
import {
    EnvelopeIcon,
    BuildingOfficeIcon,
    MapPinIcon,
    PhoneIcon,
    GlobeAltIcon,
} from "@heroicons/react/24/outline";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import axios from "axios";
import { stripHtml } from "string-strip-html";

yup.setLocale({
    mixed: {
        default: "Field is invalid",
        required: "This field is required",
    },
});

const BusinessInformation = () => {
    const increment = useAuthProcess((state) => state.increment);
    const [isLoading, setIsLoading] = React.useState(false);
    const [cities, setCities] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [isBasedInUAE, setIsBasedInUAE] = React.useState(null);
    const [licenseFile, setLicenseFile] = React.useState(null);
    const setBusinessInformationFormData = useBusinessInformationData(
        (state) => state.setFormData
    );
    const retrievedFormData = useBusinessInformationData(
        (state) => state.formData
    );

    const onboardingSchema = yup.object().shape({
        company_name: yup
            .string()
            .test("not-only-spaces", "Company name is invalid", (value) => {
                return /\S/.test(value);
            })
            .test(
                "no-leading-whitespace",
                "Company name should not start with whitespace",
                (value) => {
                    return !/^\s/.test(value);
                }
            )
            .required()
            .min(3, "Name must be at least 3 characters"),
        uae_business: yup.string().required("This is required"),
        // city_id: yup.string().required(),
        // country_id: yup.string().required(),
        company_address: yup
            .string()
            .test("not-only-spaces", "Company address is invalid", (value) => {
                return /\S/.test(value);
            })
            .test(
                "no-leading-whitespace",
                "Company address should not start with whitespace",
                (value) => {
                    return !/^\s/.test(value);
                }
            )
            .required()
            .min(3, "Name must be at least 3 characters"),
        company_phone: yup
            .string()
            .required("Phone is required")
            .matches(
                /^[\d+]+$/,
                "Phone can only contain numbers and the + sign"
            )
            .test("valid-plus-sign", "Invalid use of + sign", (value) => {
                if (!value) return true;
                return !/^\++$/.test(value);
            })
            .matches(
                /^(\+\d+)?\d+$/,
                "Phone can only contain numbers and the + sign at the beginning"
            ),
        company_email: yup
            .string()
            .email("Email Address is invalid")
            .test(
                "email-domain",
                "Email domain is not allowed",
                function (value) {
                    // Regular expression to match specified email domains
                    const forbiddenDomainsRegex =
                        /@(gmail\.com|outlook\.com|hotmail\.com|yahoo\.com|aol\.com|icloud\.com|live\.com)$/;
                    return !forbiddenDomainsRegex.test(value);
                }
            )
            .required(),
        company_website: yup
            .string()
            .matches(/^(?!https:\/\/\s*$).+/, "Website is invalid")
            .matches(/^(?!https:\/\/\s).+/, "Website is invalid")
            .required(),
        company_license: yup.string().required(),
        agreeToTerms: yup
            .boolean()
            .oneOf([true], "You must agree to the terms and conditions.")
            .required("You must agree to the terms and conditions"),
    });

    const {
        register,
        handleSubmit,
        clearErrors,
        watch,
        trigger,
        reset,
        formState: { errors },
        setValue,
        setError,
        unregister,
    } = useForm({
        resolver: yupResolver(onboardingSchema),
    });

    console.log(errors);
    console.log(watch());

    const { toast } = useToast();

    const uae_business = watch("uae_business");

    const saveUserDataInfo = async (data) => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("company_name", data.company_name);
            formData.append("uae_business", +data.uae_business);
            if (isBasedInUAE) {
                formData.append("city_id", +data.city_id);
                formData.append("country_id", null);
            } else {
                formData.append("country_id", +data.country_id);
                formData.append("city_id", null);
            }
            formData.append("company_address", data.company_address);
            formData.append("company_phone", data.company_phone);
            formData.append("company_email", data.company_email);
            formData.append("company_website", data.company_website);
            // Save external links and description
            formData.append(
                "description",
                retrievedFormData?.description ?? ""
            );
            formData.append("facebook", retrievedFormData?.facebook ?? "");
            formData.append("instagram", retrievedFormData?.instagram ?? "");
            formData.append("linkedin", retrievedFormData?.linkedin ?? "");
            formData.append("twitter", retrievedFormData?.twitter ?? "");
            formData.append("youtube", retrievedFormData?.youtube ?? "");

            // if (licenseFile == null && data.company_license) {
            //     formData.append("company_license", data.company_license);
            // }
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (res.status === 200) {
                setBusinessInformationFormData({
                    ...data,
                });
                increment();
                toast({
                    title: "Business Information saved ✅",
                    description: "Please continue the onboarding process",
                });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        const fetchCities = async () => {
            try {
                const res = await axios.get("/onboarding-cities");
                setCities(res.data.cities);
            } catch (error) {}
        };
        const fetchCountries = async () => {
            try {
                const res = await axios.get("/onboarding-countries");
                setCountries(res.data.countries);
            } catch (error) {}
        };
        const fetchFormData = async () => {
            try {
                const res = await axios.get("/onboarding-data");
                console.log("FORM DATA", res.data.data);
                if (res.data.data !== null) {
                    const {
                        address,
                        uae_business,
                        country_id,
                        city_id,
                        ...otherFields
                    } = res.data.data;
                    const parsedAddress = JSON.parse(address);
                    console.log({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id?.toString() ?? "",
                        city_id: city_id?.toString() ?? "",
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });
                    reset({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id ? "" : country_id,
                        city_id: city_id == null ? "" : city_id,
                        description: stripHtml(res.data.data.description)
                            .result,
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });

                    setIsBasedInUAE(!!res.data.data.uae_business);
                    setBusinessInformationFormData({
                        ...otherFields,
                        ...parsedAddress,
                        agreeToTerms: true,
                        uae_business: uae_business.toString(),
                        country_id: country_id,
                        description: stripHtml(res.data.data.description)
                            .result,
                        city_id: city_id,
                        ...res.data?.logo,
                        ...res.data?.banner,
                        ...res.data?.company_license_file,
                    });
                }
                // if (res.data.company_license_file) {
                //     setLicenseFile(res.data.company_license_file);
                //     setCompLicense(res.data.company_license);
                //     unregister("company_license");
                // } else {
                //     register("company_license");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        fetchCities();
        fetchCountries();
        fetchFormData();
    }, []);

    const onSubmit = (data) => {
        if (
            isBasedInUAE &&
            (retrievedFormData?.city_id == null ||
                retrievedFormData?.city_id.length == 0)
        ) {
            setError("city_id", { message: "This field is required" });
            return;
        }
        if (
            !isBasedInUAE &&
            (retrievedFormData?.country_id == null ||
                retrievedFormData?.country_id.length == 0)
        ) {
            setError("country_id", { message: "This field is required" });
            return;
        }
        saveUserDataInfo(data);
    };

    return (
        <form id="business-information-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-[120px] h-[68.56px]" src={logo} alt="logo" />
                <div className="flex h-max items-center mt-[32px] justify-center space-x-2">
                    <div className="h-[2px] w-[94px] bg-[#00833E]" />
                    <div className="h-[2px] w-[94px] bg-[#0000001A]/10" />
                </div>
                <p className="font-semibold text-2xl mt-[32px] text-black">
                    Business Information
                </p>
                <p className="w-[368px] text-left text-sm mt-2">
                    Is your business based in UAE?
                </p>
                <RadioGroup
                    className="w-[368px] space-y-1"
                    value={isBasedInUAE}
                    onValueChange={(val) => {
                        setValue("uae_business", val ? "1" : "0");
                        setIsBasedInUAE(val == "1");
                        clearErrors();
                    }}
                    // {...register("uae_business")}
                >
                    <div className="flex border-[#ACADBA] items-center space-x-2 border rounded-2xl w-full h-[36px] p-2">
                        <RadioGroupItem
                            value={true}
                            id="option-one"
                            className="border-1 border-[#ACADBA]"
                        />
                        <Label className="my-0" htmlFor="option-one">
                            Yes
                        </Label>
                    </div>
                    <div className="flex border-[#ACADBA] items-center space-x-2 border rounded-2xl w-full h-[36px] p-2">
                        <RadioGroupItem
                            value={false}
                            id="option-two"
                            className="border-1 border-[#ACADBA]"
                        />
                        <Label className="my-0" htmlFor="option-two">
                            No
                        </Label>
                    </div>
                </RadioGroup>
                {errors.uae_business && (
                    <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                        {errors.uae_business?.message}
                    </p>
                )}
                {uae_business !== undefined && uae_business !== null && (
                    <BusinessInformationForms
                        clearErrors={clearErrors}
                        isBasedInUAE={isBasedInUAE}
                        register={register}
                        errors={errors}
                        cities={cities}
                        countries={countries}
                        setValue={setValue}
                        setError={setError}
                        watch={watch}
                        trigger={trigger}
                        licenseFile={licenseFile}
                    />
                )}
                <Button
                    disabled={isLoading}
                    type="submit"
                    className="font-medium rounded-3xl mt-2 w-[368px]"
                    form="business-information-form"
                >
                    Next
                    {isLoading && (
                        <span className="ml-2">
                            <Loader2 className="h-5 animate-spin" />
                        </span>
                    )}{" "}
                </Button>
            </div>
        </form>
    );
};

const BusinessInformationForms = ({
    setValue,
    clearErrors,
    register,
    errors,
    isBasedInUAE,
    cities,
    countries,
    setError,
    watch,
    trigger,
    licenseFile,
}) => {
    const [isChecked, setIsChecked] = React.useState(false);
    const [licenseFileName, setLicenseFileName] = React.useState(null);
    const [fileUploadError, setFileUploadError] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { toast } = useToast();
    const userData = useBusinessInformationData((state) => state.formData);
    const setUserData = useBusinessInformationData(
        (state) => state.setFormData
    );
    console.log(userData);

    React.useEffect(() => {
        setValue("country_id", userData?.country_id ?? "1");
        setValue("city_id", userData?.city_id ?? "1");
    }, [userData]);

    const saveLicense = async (file) => {
        setIsLoading(true);
        console.log(userData);
        const formData = new FormData();
        formData.append("company_license", file);
        formData.append("company_address", userData.company_address);
        formData.append("company_phone", userData.company_phone);
        formData.append("company_email", userData.company_email);
        formData.append("company_website", userData.company_website);
        try {
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(res);
            if (res.status === 200) {
                toast({
                    title: "Company license uploaded ✅",
                });
                setUserData({ ...userData, license_name: file?.name });
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.name) {
                setLicenseFileName(file.name);
            }
            if (file?.size == 0) {
                console.log(file.size);
                setFileUploadError("Upload your company license");
                setValue("company_license", null);
            } else if (file?.size > 10485760) {
                console.log(file.size);
                setFileUploadError("File size too large");
                setValue("company_license", null);
            } else if (
                !(
                    file?.type === "application/pdf" &&
                    file.name.match(/\.(pdf)$/i)
                )
            ) {
                setFileUploadError("Invalid file format");
                setValue("company_license", null);
            } else {
                clearErrors("company_license");
                setFileUploadError(null);
                setValue("company_license", file.name);
                saveLicense(file);
            }
        }
    };
    return (
        <div className="w-[368px] space-y-2">
            <Separator className="my-4" />
            <Input
                placeholder="Company Name"
                type="text"
                className="w-full rounded-full"
                icon={BuildingOfficeIcon}
                {...register("company_name")}
            />
            {errors.company_name && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_name?.message}
                </p>
            )}
            <Select
                className="text-black"
                {...register(isBasedInUAE ? "city_id" : "country_id")}
                value={
                    isBasedInUAE
                        ? userData?.city_id?.toString() ?? ""
                        : userData?.country_id?.toString() ?? ""
                }
                onValueChange={(val) => {
                    if (isBasedInUAE) {
                        // setValue("city_id", val);
                        // setValue("country_id", "1");
                        setUserData({ ...userData, city_id: val });
                        trigger("city_id");
                    } else {
                        // setValue("country_id", val);
                        // setValue("city_id", "1");
                        setUserData({ ...userData, country_id: val });
                        trigger("country_id");
                    }
                }}
            >
                <SelectTrigger className="w-full rounded-full">
                    <SelectValue
                        className="text-black"
                        placeholder={
                            isBasedInUAE ? "Select Emirate" : "Select country"
                        }
                    />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {isBasedInUAE ? (
                            <SelectLabel>City</SelectLabel>
                        ) : (
                            <SelectLabel>Country</SelectLabel>
                        )}
                        {isBasedInUAE
                            ? cities.map((city, index) => {
                                  return (
                                      <SelectItem
                                          value={city.id.toString()}
                                          key={index}
                                      >
                                          {city.name.en}
                                      </SelectItem>
                                  );
                              })
                            : countries.map((country, index) => {
                                  return (
                                      <SelectItem
                                          key={country.name.en}
                                          value={country.id.toString()}
                                          id={index}
                                      >
                                          {country.name.en}
                                      </SelectItem>
                                  );
                              })}
                    </SelectGroup>
                </SelectContent>
            </Select>
            {isBasedInUAE
                ? errors.city_id && (
                      <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                          {errors.city_id?.message}
                      </p>
                  )
                : errors.country_id && (
                      <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                          {errors.country_id?.message}
                      </p>
                  )}
            <Input
                placeholder="Address"
                type="text"
                className="w-full rounded-full"
                icon={MapPinIcon}
                {...register("company_address")}
            />
            {errors.company_address && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_address?.message}
                </p>
            )}
            <Input
                placeholder="Phone"
                type="tel"
                className="w-full rounded-full"
                icon={PhoneIcon}
                {...register("company_phone")}
            />
            {errors.company_phone && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_phone?.message}
                </p>
            )}
            <Input
                placeholder="Email"
                className="w-full rounded-full"
                icon={EnvelopeIcon}
                {...register("company_email")}
            />
            {errors.company_email && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_email?.message}
                </p>
            )}
            <Input
                placeholder="Website"
                type="text"
                className="w-full rounded-full"
                icon={GlobeAltIcon}
                {...register("company_website")}
                onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value == "https://") {
                        setValue("company_website", "");
                        return;
                    }
                    if (!e.target.value.startsWith("https://")) {
                        setValue(
                            "company_website",
                            "https://" + e.target.value
                        );
                    } else {
                        setValue("company_website", e.target.value);
                    }
                    trigger("company_website");
                }}
            />
            {errors.company_website && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_website?.message}
                </p>
            )}
            <div className="flex items-center border rounded-[18px] py-[11px] px-[16px]">
                <div className="flex-1">
                    <p className="m-0 text-black text-md">
                        Upload Company License
                    </p>
                    <p className="m-0 text-sm">Max file size: 10 Mb</p>
                    {userData?.license_name && (
                        <Button
                            type="button"
                            className="m-0 p-2"
                            variant={"ghost"}
                        >
                            {/* <a
                                href={userData?.company_license}
                                className="text-black m-0 text-xs"
                            > */}
                            <p className="w-32 truncate">
                                {userData?.license_name}
                            </p>
                            {/* </a> */}
                        </Button>
                    )}
                    <input
                        className="hidden"
                        type="file"
                        accept=".pdf"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </div>

                <Button
                    className="bg-[#ACADBA] rounded-lg p-2"
                    type="button"
                    onClick={handleButtonClick}
                >
                    <ArrowUpTrayIcon className="w-6 text-white" />
                </Button>
            </div>
            {fileUploadError && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {fileUploadError}
                </p>
            )}
            {fileUploadError == null && errors.company_license && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_license?.message}
                </p>
            )}
            {/* {watch("company_license") == undefined && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    Upload your company license
                </p>
            )} */}
            <div className="flex px-2 items-center py-[11px] justify-start">
                <input
                    type="checkbox"
                    className="border-black"
                    id="terms1"
                    value={isChecked.toString()}
                    {...register("agreeToTerms")}
                    onChange={(e) => {
                        setIsChecked(e.target.checked);
                        setValue("agreeToTerms", e.target.checked);
                        clearErrors("agreeToTerms");
                    }}
                />
                <div className="ml-3 grid gap-1.5 leading-none">
                    <p className="m-0 text-sm text-muted-foreground">
                        I have read and agree to the{" "}
                        <a target="_blank" href="/terms-and-conditions">
                            Terms & Conditions
                        </a>{" "}
                        and the{" "}
                        <a target="_blank" href="/privacy-policy">
                            Privacy Policy
                        </a>{" "}
                        of this website
                    </p>
                </div>
            </div>
            {errors.agreeToTerms && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.agreeToTerms?.message}
                </p>
            )}
        </div>
    );
};

export default BusinessInformation;
