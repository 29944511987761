import React, { useState } from "react";
import axios from "axios";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/login", { email, password });
            console.log(response.data);
            // Handle successful login
        } catch (error) {
            if (error.response && error.response.data.errors) {
                setErrors(error.response.data.errors);
            }
        }
    };

    return (
        <section className="relative p-0 float-left">
            <div className="absolute">
                <div className="container mx-auto">
                    <div className="flex items-center">
                        <div className="w-full max-w-lg">
                            {errors.length > 0 &&
                                errors.map((error, index) => (
                                    <div
                                        key={index}
                                        className="alert alert-danger mt-1 alert-validation-msg"
                                        role="alert"
                                    >
                                        <div className="flex items-center">
                                            <span className="text-red-500">
                                                {error}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            <div className="p-4 shadow-lg">
                                <p className="text-5xl font-bold text-black leading-tight">
                                    Welcome back!
                                </p>
                                <p className="text-md text-gray-500 my-4">
                                    Fill your email address and password to sign
                                    in
                                </p>
                                <form className="mt-2" onSubmit={handleLogin}>
                                    <div className="mb-6">
                                        <label
                                            htmlFor="email"
                                            className="block text-black mb-2"
                                        >
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            className="w-full p-3 border border-gray-300 rounded"
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                    <div className="mb-6 relative">
                                        <label
                                            htmlFor="password"
                                            className="block text-black mb-2"
                                        >
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            className="w-full p-3 border border-gray-300 rounded"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            required
                                            minLength="8"
                                        />
                                        <span
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        >
                                            <i
                                                className={`fa ${
                                                    showPassword
                                                        ? "fa-eye-slash"
                                                        : "fa-eye"
                                                } text-gray-400`}
                                            ></i>
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center mb-6">
                                        <label className="inline-flex items-center text-sm">
                                            <input
                                                type="checkbox"
                                                defaultChecked
                                                className="form-checkbox"
                                            />
                                            <span className="ml-2 text-gray-700">
                                                Remember me
                                            </span>
                                        </label>
                                        <a
                                            className="text-sm text-blue-600 hover:underline"
                                            href="/password/request"
                                        >
                                            Forgot your password?
                                        </a>
                                    </div>
                                    <div className="mb-6">
                                        <button
                                            className="w-full py-3 bg-blue-600 text-white font-semibold rounded"
                                            type="submit"
                                        >
                                            Log in
                                        </button>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <span className="text-gray-700 mr-2">
                                            Don’t have an account?
                                        </span>
                                        <a
                                            href="/register"
                                            className="text-blue-600 hover:underline"
                                        >
                                            Create an Account
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex m-0">
                <div className="w-full max-w-lg" style={{ zIndex: -1 }}></div>
                <div className="flex-grow-0 login-visual">
                    <div className="pl-20">
                        <img
                            className="w-full"
                            src="/path/to/banner.png"
                            alt="iori"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
