import React from "react";
import logo from "../../../../website_theme/img/logo-white.png";
import useAuthProcess from "../../../zustand/authProcessIndex.js";
import noDataUndraw from "../../../../website_theme/img/undraw_no_data.svg";
import bgImage from "../../../../website_theme/img/company-information-background.png";
import { Button } from "@/components/ui/button";
import useBusinessInformationData from "../../../zustand/businessInformationData.js";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { useToast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

import { cn } from "@/lib/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

Yup.setLocale({
    mixed: {
        default: "Field is invalid",
        required: "This field is required",
    },
});

const stepOneSchema = Yup.object().shape({
    description: Yup.string()
        .max(1000, "Description cannot exceed 1000 characters.")
        .required(),
    company_name: Yup.string().required(),
});

const stepTwoSchema = Yup.object().shape({
    company_email: Yup.string().email("Email Address is invalid").required(),
    company_phone: Yup.string()
        .required("Phone is required")
        .matches(/^[\d+]+$/, "Phone can only contain numbers and the + sign")
        .test("valid-plus-sign", "Invalid use of + sign", (value) => {
            if (!value) return true;
            return !/^\++$/.test(value);
        })
        .matches(
            /^(\+\d+)?\d+$/,
            "Phone can only contain numbers and the + sign at the beginning"
        ),
    company_address: Yup.string().required(),
});

const stepThreeSchema = Yup.object().shape({
    company_website: Yup.string()
        .nullable()
        .matches(/^(?!https:\/\/\s*$).+$/, "Website is invalid")
        .matches(/^(?!https:\/\/\s).+$/, "Website is invalid"),
    facebook: Yup.string()
        .nullable()
        .test("valid-url", "Invalid Facebook URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?facebook.com\/\S*$/.test(value)
            );
        }),
    instagram: Yup.string()
        .nullable()
        .test("valid-url", "Invalid Instagram URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?instagram.com\/\S*$/.test(value)
            );
        }),
    linkedin: Yup.string()
        .nullable()
        .test("valid-url", "Invalid LinkedIn URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?linkedin.com\/\S*$/.test(value)
            );
        }),
    youtube: Yup.string()
        .nullable()
        .test("valid-url", "Invalid YouTube URL", function (value) {
            return (
                !value || /^(https?:\/\/)?(www\.)?youtube.com\/\S*$/.test(value)
            );
        }),
    twitter: Yup.string()
        .nullable()
        .test("valid-url", "Invalid Twitter URL", function (value) {
            return (
                !value ||
                /^(https?:\/\/)?(www\.)?twitter.com\/\S*$/.test(value) ||
                /^(https?:\/\/)?(www\.)?x.com\/\S*$/.test(value)
            );
        }),
});

const stepFourSchema = Yup.object().shape({
    has_added_team: Yup.bool()
        .required("Please add at least one team member")
        .oneOf([true], "Please add at least one team member"),
    team_country_id: Yup.string().required(),
    team_linkedin: Yup.string().nullable(),
    gender: Yup.string().required(),
    team_image: Yup.mixed()
        .required()
        .test("file", "Invalid file format", (value) => {
            return value && value instanceof File;
        }),
    name: Yup.string()
        .required()
        .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and spaces")
        .matches(/^\S.*\S$/, "Name cannot start or end with whitespace"),
    title: Yup.string()
        .required()
        .matches(/^\S.*\S$/, "Position cannot start or end with whitespace")
        .matches(/\S/, "Title cannot consist only of whitespace"),
});

const stepFiveSchema = Yup.object().shape({
    award_title: Yup.string().required(),
    award_date: Yup.string().required(),
    award_description: Yup.string().required(),
    // award_image: Yup.mixed().test("file", "Invalid file format", (value) => {
    //     return value && value instanceof File;
    // }),
});

const CompanyInformation = () => {
    const decrement = useAuthProcess((state) => state.decrement);
    const schemas = [
        stepOneSchema,
        stepTwoSchema,
        stepThreeSchema,
        stepFourSchema,
        stepFiveSchema,
    ];
    const [stepIndex, setStepIndex] = React.useState(0);
    const data = useBusinessInformationData((state) => state.formData);
    const [isLoading, setIsLoading] = React.useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
        trigger,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schemas[stepIndex]),
    });

    const [hasAddedATeamMember, setHasAddedATeamMember] = React.useState(0);
    const [hasAddedAward, setHasAddedAward] = React.useState(0);

    const { toast } = useToast();

    React.useEffect(() => {
        if (stepIndex == 3) {
            if (errors?.has_added_team) {
                toast({
                    variant: "destructive",
                    title: errors.has_added_team?.message,
                });
            }
        }
    }, [stepIndex, errors]);

    const steps = [
        <CompanyInformationStep1
            register={register}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
            watch={watch}
        />,
        <CompanyInformationStep2 register={register} errors={errors} />,
        <CompanyInformationStep3
            register={register}
            errors={errors}
            trigger={trigger}
            setValue={setValue}
        />,
        <CompanyInformationStep4
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            isLoading={isLoading}
            errors={errors}
            hasAddedATeamMember={hasAddedATeamMember}
        />,
        <CompanyInformationStep5
            setValue={setValue}
            watch={watch}
            errors={errors}
            hasAddedATeamMember={hasAddedATeamMember}
            register={register}
            isLoading={isLoading}
            hasAddedAward={hasAddedAward}
        />,
    ];

    //
    console.log(errors);
    register("banner");
    register("logo");
    console.log(data);
    // React.useEffect(() => {
    //   setValue('company_logo', data?.logo_name);
    // }, [])

    const saveUserDataInfo = async (data) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("company_address", data.company_address);
        formData.append("company_phone", data.company_phone);
        formData.append("company_email", data.company_email);
        formData.append("company_website", data.company_website);
        if (stepIndex == 0) {
            formData.append("description", data?.description);
            formData.append("company_name", data?.company_name);
        }
        if (stepIndex == 2) {
            formData.append("facebook", data?.facebook ?? "");
            formData.append("instagram", data?.instagram ?? "");
            formData.append("linkedin", data?.linkedin ?? "");
            formData.append("twitter", data?.twitter ?? "");
            formData.append("youtube", data?.youtube ?? "");
        }

        try {
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (res.status === 200) {
                if (stepIndex == 0) {
                    toast({
                        title: "Company Information saved ✅",
                        description: "Please proceed to the next steps",
                    });
                }
                if (stepIndex == 1) {
                    toast({
                        title: "Contact Information saved ✅",
                        description: "Please proceed to the next steps",
                    });
                }
                if (stepIndex == 2) {
                    toast({
                        title: "Company links saved ✅",
                        description: "Please proceed to the next steps",
                    });
                }
                setStepIndex(stepIndex + 1);
            }
        } catch (error) {}
        setIsLoading(false);
    };

    const saveTeam = async (teamData) => {
        console.log(teamData);
        setIsLoading(true);
        const teamFormData = {
            title: teamData?.title,
            name: teamData?.name,
            linkedin: teamData?.team_linkedin,
            nationality: teamData?.team_country_id,
            gender: teamData?.gender,
            image: teamData?.team_image,
        };

        try {
            const res = await axios.post("/add-team", teamFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(res);
            toast({
                title: "Success!",
                description: "New team member added",
            });
            setValue("has_added_team", true);
            setValue("title", "");
            setValue("name", "");
            setValue("team_linkedin", data?.linkedin ?? "");
            setValue("team_country_id", "");
            setValue("gender", "");
            setValue("team_image", null);
            setHasAddedATeamMember((prev) => prev + 1);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const saveAward = async (data) => {
        console.log(data);
        setIsLoading(true);
        const award = {
            title: data?.award_title,
            date: data?.award_date,
            description: data?.award_description,
            image: data?.award_image,
        };
        try {
            const res = await axios.post("/add-award", award, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast({
                title: "Success!",
                description: "Award saved",
            });
            reset({
                award_title: "",
                award_date: "",
                award_description: "",
            });
            setHasAddedAward((prev) => prev + 1);
        } catch (error) {}
        setIsLoading(false);
    };

    const onSubmit = (data) => {
        if (stepIndex >= 0 && stepIndex <= 2) {
            console.log(stepIndex, data?.logo);
            if (stepIndex == 0 && data?.logo == null) {
                toast({
                    variant: "destructive",
                    title: "Logo is required",
                    description: "Please upload a logo",
                });
            } else {
                saveUserDataInfo(data);
            }
        }
        if (stepIndex == 3) {
            saveTeam(data);
        }
        if (stepIndex == 4) {
            saveAward(data);
        }
    };

    const currentFormValues = watch();
    React.useEffect(() => {
        reset({
            ...currentFormValues,
            ...data,
        });
    }, []);

    return (
        <div
            className="bg-cover bg-center overflow-y-auto py-8 min-h-screen w-full flex flex-col justify-center items-center"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <img className="w-[120px] h-[68.56px]" src={logo} alt="logo" />
            <div className="flex h-max items-center mt-[32px] justify-center space-x-2">
                <div
                    className={cn(
                        "h-[2px] w-[24px] sm:w-[70px]",
                        stepIndex == 0 ? "bg-white" : "bg-white/40"
                    )}
                />
                <div
                    className={cn(
                        "h-[2px] w-[24px] sm:w-[70px]",
                        stepIndex == 1 ? "bg-white" : "bg-white/40"
                    )}
                />
                <div
                    className={cn(
                        "h-[2px] w-[24px] sm:w-[70px]",
                        stepIndex == 2 ? "bg-white" : "bg-white/40"
                    )}
                />
                <div
                    className={cn(
                        "h-[2px] w-[24px] sm:w-[70px]",
                        stepIndex == 3 ? "bg-white" : "bg-white/40"
                    )}
                />
                <div
                    className={cn(
                        "h-[2px] w-[24px] sm:w-[70px]",
                        stepIndex == 4 ? "bg-white" : "bg-white/40"
                    )}
                />
            </div>
            <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="m-8 sm:m-0 sm:mt-[32px] p-8 w-80 sm:w-full sm:p-8 mt-[32px] flex flex-col justify-center items-center bg-white rounded-[16px]">
                    {steps[stepIndex]}
                    <div className="flex space-x-2 mt-4 w-full">
                        <Button
                            type="button"
                            onClick={() => {
                                if (stepIndex !== 0 && !isLoading) {
                                    setStepIndex(stepIndex - 1);
                                }
                                if (stepIndex == 0) {
                                    decrement();
                                }
                            }}
                            className="rounded-3xl w-full flex justify-center items-center"
                            disabled={isLoading}
                        >
                            Back
                        </Button>
                        <Button
                            type={stepIndex <= 2 ? "submit" : "button"}
                            onClick={() => {
                                if (stepIndex == 3) {
                                    const hasAdded = watch("has_added_team");
                                    if (!errors?.has_added_team && hasAdded) {
                                        setStepIndex(stepIndex + 1);
                                    } else {
                                        toast({
                                            variant: "destructive",
                                            title: "Please add at least one team member",
                                        });
                                    }
                                }
                                if (stepIndex == 4) {
                                    window.location.href = "/user-dashboard";
                                }
                            }}
                            disabled={isLoading}
                            className="rounded-3xl text-xs sm:text-base flex w-full justify-center items-center"
                        >
                            {stepIndex == 4 ? "Finish" : "Next"}
                            {isLoading && stepIndex !== 4 && (
                                <span className="ml-2">
                                    <Loader2 className="h-5 animate-spin" />
                                </span>
                            )}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const CompanyInformationStep1 = ({ register, errors, setValue }) => {
    const fileInputRef = React.useRef(null);

    const [logo, setLogo] = React.useState(null);
    const [banner, setBanner] = React.useState(null);
    const [license, setLicense] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [fileType, setFileType] = React.useState(null);
    const [error, setError] = React.useState(null);
    const userData = useBusinessInformationData((state) => state.formData);
    const setUserData = useBusinessInformationData(
        (state) => state.setFormData
    );
    const { toast } = useToast();

    React.useEffect(() => {
        setLogo(userData?.logo_name);
        setBanner(userData?.banner_name);
    }, [userData]);

    const saveFile = async (file) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append(fileType, file);
        console.log(userData);
        formData.append("company_address", userData.company_address);
        formData.append("company_phone", userData.company_phone);
        formData.append("company_email", userData.company_email);
        formData.append("company_website", userData.company_website);
        try {
            const res = await axios.post("/save-user-type-data", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(res);

            if (res.status === 200) {
                if (fileType == "company_license") {
                    toast({
                        title: "Company license updated ✅",
                    });
                    setUserData({ ...userData, license_name: license });
                    setValue("license", license);
                } else if (fileType == "logo") {
                    toast({
                        title: "Logo updated ✅",
                    });
                    setUserData({ ...userData, logo_name: file.name });
                    // setValue("logo", logo);
                    // clearErrors("logo");
                } else {
                    toast({
                        title: "Banner updated ✅",
                    });
                    setUserData({ ...userData, banner_name: file.name });
                    setValue("banner", banner);
                    // clearErrors("banner");
                }
            }
        } catch (error) {}
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file?.size > 10485760) {
            setError("File size too large");
        } else if (
            file?.type === "application/pdf" &&
            file.name.match(/\.(pdf)$/i) &&
            fileType !== "company_license" &&
            fileType !== null
        ) {
            setError("Invalid file format");
        } else if (
            file?.type.startsWith("image/") &&
            /\.(jpg|jpeg|png)$/i.test(file.name) &&
            fileType != null &&
            fileType == "company_license"
        ) {
            setError("Invalid file format");
        } else {
            if (fileType == "logo") {
                setLogo(file?.name);
            }
            if (fileType == "banner") {
                setBanner(file?.name);
            }
            if (fileType == "company_license") {
                setLicense(file?.name);
            }
            setValue("logo", file?.name);
            saveFile(file);
            // clearErrors("company_license");
            // setValue("company_license", file);
        }
    };

    return (
        <div className="flex flex-col space-y-[8px] w-full">
            <p className="font-semibold text-center text-2xl text-black">
                Company Information
            </p>
            <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                <div className="flex-1">
                    <p className="m-0 text-black text-md">Add Company Logo</p>
                    <p className="m-0 mr-2 text-sm">
                        Recommended size: 600px x 600px
                    </p>
                    {logo !== null && (
                        <Button
                            type="button"
                            className="hover:cursor-default m-0 p-0"
                            variant={"ghost"}
                        >
                            <p className="text-black m-0 text-xs w-32 truncate">
                                {logo}
                            </p>
                        </Button>
                    )}
                    <input
                        className="hidden"
                        accept=".jpg, .jpeg, .png"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </div>
                <Button
                    className="bg-[#ACADBA] rounded-lg p-2"
                    type="button"
                    onClick={() => {
                        handleButtonClick();
                        setFileType("logo");
                    }}
                >
                    <ArrowUpTrayIcon className="w-6 text-white" />
                </Button>
            </div>
            {/* {fileType == "logo" && error && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {error}
                </p>
            )} */}
            {errors?.logo && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.logo?.message}
                </p>
            )}
            <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                <div className="flex-1">
                    <p className="m-0 text-black text-md">Add Company Banner</p>
                    <p className="m-0 text-sm">
                        Recommended size: 1920px x 600px
                    </p>
                    {banner !== null && (
                        <Button
                            type="button"
                            className=" hover:cursor-none m-0 p-0"
                            variant={"ghost"}
                        >
                            <p className="text-black m-0 text-xs w-32 truncate">
                                {banner}
                            </p>
                        </Button>
                    )}
                </div>
                <Button
                    className="bg-[#ACADBA] rounded-lg p-2"
                    type="button"
                    onClick={() => {
                        handleButtonClick();
                        setFileType("banner");
                    }}
                >
                    <ArrowUpTrayIcon className="w-6 text-white" />
                </Button>
            </div>
            {fileType == "banner" && error && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {error}
                </p>
            )}{" "}
            <Input
                placeholder="Address"
                className="w-full rounded-[18px]"
                {...register("company_name")}
            />
            {/* <Input
                    className="w-full flex-grow sm:w-[368px]"
                    placeholder="Company Name"
                    {...register("company_name")}
                /> */}
            {errors.company_name && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_name?.message}
                </p>
            )}
            <Textarea
                placeholder="Tell us more about your company."
                className="w-full rounded-[18px]"
                {...register("description")}
            />
            {errors.description && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.description?.message}
                </p>
            )}
            {/* <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                <div className="flex-1">
                    <p className="m-0 text-black text-md">
                        Upload Company License
                    </p>
                    <p className="m-0 text-sm">Max file size: 10 Mb</p>
                    {license !== null && (
                        <Button
                            type="button"
                            className="hover:cursor-default m-0 p-0"
                            variant={"ghost"}
                        >
                            <p className="text-black m-0 text-xs w-32 truncate">
                                {license}
                            </p>
                        </Button>
                    )}
                </div>
                <Button
                    className="bg-[#ACADBA] rounded-lg p-2"
                    type="button"
                    onClick={() => {
                        handleButtonClick();
                        setFileType("company_license");
                    }}
                >
                    <ArrowUpTrayIcon className="w-6 text-white" />
                </Button>
            </div>
            {fileType == "company_license" && error && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {error}
                </p>
            )} */}
        </div>
    );
};

const CompanyInformationStep2 = ({ register, errors }) => {
    return (
        <div className="flex flex-col space-y-[8px] w-full">
            <p className="font-semibold text-center text-2xl text-black">
                Contact Information
            </p>
            <Input
                placeholder="Email"
                className="w-full"
                {...register("company_email")}
            />
            {errors.company_email && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_email?.message}
                </p>
            )}
            <Input
                placeholder="Contact Number"
                className="w-full"
                {...register("company_phone")}
            />
            {errors.company_phone && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_phone?.message}
                </p>
            )}
            <Input
                placeholder="Address"
                className="w-full"
                {...register("company_address")}
            />
            {errors.company_address && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_address?.message}
                </p>
            )}
        </div>
    );
};

const CompanyInformationStep3 = ({ register, errors, trigger, setValue }) => {
    return (
        <div className="flex flex-col space-y-[8px] w-full">
            <p className="font-semibold text-center text-2xl text-black">
                Company Links
            </p>
            <p className="text-black text-sm m-0">
                Add Company Website
                <span
                    className="text-red text-xs ml-1"
                    style={{ color: "red" }}
                >
                    (Required)
                </span>
            </p>
            <Input
                required
                {...register("company_website")}
                placeholder="Website"
                onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value == "https://") {
                        setValue("company_website", "");
                        return;
                    }
                    if (!e.target.value.startsWith("https://")) {
                        setValue(
                            "company_website",
                            "https://" + e.target.value
                        );
                    } else {
                        setValue("company_website", e.target.value);
                    }
                    trigger("company_website");
                }}
            />{" "}
            {errors.company_website && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.company_website?.message}
                </p>
            )}
            <p className="text-black text-sm">Add other links</p>
            <Input {...register("facebook")} placeholder="Facebook" />
            {errors.facebook && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.facebook?.message}
                </p>
            )}
            <Input {...register("instagram")} placeholder="Instagram" />
            {errors.instagram && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.instagram?.message}
                </p>
            )}
            <Input {...register("linkedin")} placeholder="Linkedin" />
            {errors.linkedin && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.linkedin?.message}
                </p>
            )}
            <Input {...register("youtube")} placeholder="YouTube" />
            {errors.youtube && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.youtube?.message}
                </p>
            )}
            <Input {...register("twitter")} placeholder="Twitter" />
            {errors.twitter && (
                <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                    {errors.twitter?.message}
                </p>
            )}
        </div>
    );
};

function Tag({ children }) {
    return (
        <div className="justify-center px-2 py-0.5 whitespace-nowrap rounded bg-zinc-200">
            {children}
        </div>
    );
}

function CompanyInformationStep4({
    register,
    watch,
    setValue,
    isLoading,
    trigger,
    errors,
    hasAddedATeamMember,
}) {
    // just for showing the interaction
    const [isDoneAddingTeam, setIsDoneAddingTime] = React.useState(0);
    const [teamMembers, setTeamMembers] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [countries, setCountries] = React.useState([]);
    const [fileUploadError, setFileUploadError] = React.useState(null);
    const fileInputRef = React.useRef(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size == 0) {
                console.log(file.size);
                setFileUploadError("Upload your company license");
                setValue("team_image", null);
            } else if (file?.size > 10485760) {
                console.log(file.size);
                // setFileUploadError("File size too large");
                setValue("team_image", null);
            } else if (
                !(
                    (
                        file?.type.includes("image/") && // Check if the file type starts with 'image/'
                        /\.(jpg|jpeg|png)$/i.test(file.name)
                    ) // Check if the file extension is one of the specified image formats
                )
            ) {
                // setFileUploadError("Invalid file format");
                setValue("team_image", null);
            } else {
                setValue("team_image", file);
                trigger("team_image");
            }
        }
    };

    React.useEffect(() => {
        const getTeamMembers = async () => {
            try {
                const res = await axios.get("/team-members-data");

                setTeamMembers(res.data.teams);
                setIsOpen(false);
                setValue("has_added_team", res.data.teams.length !== 0);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchCountries = async () => {
            try {
                const res = await axios.get("/onboarding-countries");
                setCountries(res.data.countries);
            } catch (error) {}
        };
        getTeamMembers();
        fetchCountries();
    }, [hasAddedATeamMember]);

    return (
        <div className="flex flex-col justify-center items-center w-full h-full max-w-full bg-white rounded-2xl">
            <div className="text-2xl font-semibold text-gray-900">
                Team Information
            </div>
            {teamMembers.length !== 0 ? (
                <div className="flex flex-col max-h-96 p-2 w-full mt-4 overflow-y-auto space-y-4">
                    {teamMembers.map((member) => {
                        console.log(member);
                        return (
                            <div className="flex flex-col gap-4 sm:flex-row self-stretch items-center p-4 bg-gray-50 rounded-2xl border border-gray-300 border-solid w-full">
                                <div className="flex flex-col self-stretch items-start justify-start w-full">
                                    <div className="flex gap-2 justify-between items-start text-left">
                                        <div className="text-sm font-semibold leading-5 text-zinc-800">
                                            {member.title}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-2 text-sm leading-5 text-zinc-800">
                                        <div className="my-auto">
                                            {member.name}
                                        </div>
                                    </div>
                                    {member?.linkedin && (
                                        <div className="flex items-center gap-1.5 mt-2 text-sm leading-5 whitespace-nowrap text-zinc-800">
                                            {member?.linkedin && (
                                                <a
                                                    href={
                                                        member.linkedin.startsWith(
                                                            "https"
                                                        )
                                                            ? member.linkedin
                                                            : `https://www.linkedin.com/company/${member.linkedin}`
                                                    }
                                                    target="_blank"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0px"
                                                        y="0px"
                                                        width="25"
                                                        height="25"
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <path
                                                            fill="#0288D1"
                                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                                        ></path>
                                                        <path
                                                            fill="#FFF"
                                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <img
                                    loading="lazy"
                                    src={member.image}
                                    alt=""
                                    className="w-full sm:w-24 aspect-square"
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <>
                    <img
                        loading="lazy"
                        src={noDataUndraw}
                        className="max-w-full mt-4 w-[120px]"
                    />
                    <div className="self-stretch mt-4 text-sm leading-5 text-center max-md:mx-1">
                        There is no team information available at the moment.
                    </div>
                </>
            )}
            <Dialog
                open={isOpen}
                onOpenChange={(va) => {
                    setIsOpen(va);
                }}
            >
                <DialogTrigger asChild>
                    <Button
                        onClick={() => {
                            setIsOpen(true);
                            setValue("has_added_team", true);
                        }}
                        variant={"outline"}
                        className="flex gap-2 justify-center mt-4 px-4 py-2 text-base font-medium leading-6 border border-gray-400 border-solid rounded-[45px]"
                    >
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54fc1e01636e7295a5352869767576129386d21f19d7b17803ead42955bf3f4f?apiKey=2863e55464904361bde551202a8063a4&"
                            className="shrink-0 my-auto w-5 aspect-square"
                        />
                        <div> Add Team</div>
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="text-2xl">
                            Team Details
                        </DialogTitle>
                    </DialogHeader>
                    <div>
                        <Input
                            className="w-full"
                            placeholder="Full Name"
                            {...register("name")}
                            required
                        />
                        {errors.name && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                {errors.name?.message}
                            </p>
                        )}
                    </div>
                    <div>
                        <Input
                            className="w-full"
                            placeholder="Position"
                            {...register("title")}
                            required
                        />
                        {errors.title && (
                            <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {errors.title?.message}
                            </p>
                        )}
                    </div>
                    <div>
                        <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                            <div className="flex-1">
                                <p className="m-0 text-black text-md">
                                    Add Photo
                                </p>
                                <p className="m-0 text-sm">
                                    Recommended size: 600px x 600px
                                </p>
                                {watch("team_image") !== null &&
                                    watch("team_image") !== undefined && (
                                        <Button
                                            type="button"
                                            className=" hover:cursor-none m-0 p-0"
                                            variant={"ghost"}
                                        >
                                            <p className="text-black m-0 text-xs w-32 truncate">
                                                {watch("team_image")?.name}
                                            </p>
                                        </Button>
                                    )}
                            </div>
                            <Button
                                className="bg-[#ACADBA] rounded-lg p-2"
                                type="button"
                                onClick={() => {
                                    handleButtonClick();
                                }}
                            >
                                <ArrowUpTrayIcon className="w-6 text-white" />
                            </Button>{" "}
                            <input
                                className="hidden"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </div>{" "}
                        {fileUploadError && (
                            <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {fileUploadError}
                            </p>
                        )}
                        {fileUploadError == null && errors.team_image && (
                            <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {errors.team_image?.message}
                            </p>
                        )}
                    </div>
                    <Input
                        className="w-full"
                        placeholder="LinkedIn"
                        {...register("team_linkedin")}
                    />
                    <div>
                        <Select
                            {...register("gender")}
                            onValueChange={(val) => {
                                setValue("gender", val);
                                trigger("gender");
                            }}
                            required
                        >
                            <SelectTrigger className="w-full rounded-full">
                                <SelectValue placeholder="Gender" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="Male">Male</SelectItem>
                                <SelectItem value="Female">Female</SelectItem>
                            </SelectContent>
                        </Select>
                        {errors.gender && (
                            <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {errors.gender?.message}
                            </p>
                        )}
                    </div>
                    <div>
                        <Select
                            className="text-black"
                            {...register("team_country_id")}
                            onValueChange={(val) => {
                                setValue("team_country_id", val.toString());
                                trigger("team_country_id");
                            }}
                            required
                        >
                            <SelectTrigger className="w-full rounded-full">
                                <SelectValue
                                    className="text-black"
                                    placeholder={"Select nationality"}
                                />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Country</SelectLabel>
                                    {countries.map((country, index) => {
                                        return (
                                            <SelectItem
                                                key={country.name.en}
                                                value={country.id.toString()}
                                                id={index}
                                            >
                                                {country.name.en}
                                            </SelectItem>
                                        );
                                    })}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        {errors.team_country_id && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                {errors.team_country_id?.message}
                            </p>
                        )}
                    </div>
                    <Button
                        // onClick={() => {
                        //     console.log(Object.keys(errors));
                        //     if (Object.keys(errors).length == 0) {
                        //         setIsDoneAddingTime((prev) => prev + 1);
                        //     }
                        // }}
                        disabled={isLoading}
                        type="submit"
                        form="hook-form"
                    >
                        Add Team
                        {isLoading && (
                            <span className="ml-2">
                                <Loader2 className="h-5 animate-spin" />
                            </span>
                        )}{" "}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function Award({ title, date, description, img }) {
    return (
        <div className="flex-col flex sm:flex-row w-full gap-4 justify-between items-center self-stretch p-4 text-xs bg-gray-50 rounded-2xl border border-gray-300 border-solid max-w-[368px] text-zinc-500">
            <header className="flex flex-col gap-2 justify-between text-sm leading-5 text-zinc-800">
                <p className="my-auto">{title}</p>
                <div className="justify-center w-min px-2 py-0.5 whitespace-nowrap rounded bg-zinc-200">
                    {date}
                </div>
                <p className="mt-2 leading-5 text-ellipsis">{description}</p>
            </header>
            {!img.endsWith("plana.jpeg") && (
                <img
                    loading="lazy"
                    src={img}
                    alt={`award`}
                    className="my-auto h-24 w-full sm:w-32 object-cover"
                />
            )}
        </div>
    );
}

function CompanyInformationStep5({
    watch,
    errors,
    register,
    isLoading,
    hasAddedAward,
    setValue,
}) {
    // just for showing the interaction
    const [isDoneAddingTeam, setIsDoneAddingTime] = React.useState(0);
    const [awards, setAwards] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [fileUploadError, setFileUploadError] = React.useState(null);
    const fileInputRef = React.useRef(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not initialized!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file?.size == 0) {
                console.log(file.size);
                setFileUploadError("Upload your company license");
                setValue("award_image", null);
            } else if (file?.size > 10485760) {
                console.log(file.size);
                // setFileUploadError("File size too large");
                setValue("award_image", null);
            } else if (
                !(
                    (
                        file?.type.includes("image/") && // Check if the file type starts with 'image/'
                        /\.(jpg|jpeg|png)$/i.test(file.name)
                    ) // Check if the file extension is one of the specified image formats
                )
            ) {
                // setFileUploadError("Invalid file format");
                setValue("award_image", null);
            } else {
                setValue("award_image", file);
                trigger("award_image");
            }
        }
    };

    const maxDate = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }

        today = yyyy + "-" + mm + "-" + dd;
        return today;
    };

    React.useEffect(() => {
        const getAwards = async () => {
            try {
                const res = await axios.get("/awards-data");

                setAwards(res.data.awards);
                setIsOpen(false);
            } catch (error) {}
        };
        getAwards();
    }, [hasAddedAward]);

    const handleAwardDateChange = (event) => {
        const enteredDate = new Date(event.target.value);
        const currentDate = new Date();
        if (enteredDate > currentDate) {
            // If the entered date is in the future, revert to the latest date
            setValue("award_date", currentDate.toISOString().slice(0, 10)); // Format date as 'YYYY-MM-DD'
        } else {
            setValue("award_date", event.target.value); // Set the entered date
        }
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full max-w-full bg-white rounded-2xl">
            <div className="text-2xl font-semibold text-gray-900">
                Awards Information
            </div>
            {awards.length !== 0 ? (
                <div className="flex flex-col h-min max-h-96 p-2 w-full mt-4 overflow-y-auto space-y-4">
                    {awards.map((award) => (
                        <Award
                            key={award.id}
                            title={award.title}
                            date={award.date}
                            description={award.description}
                            img={award.image}
                        />
                    ))}
                </div>
            ) : (
                <>
                    <img
                        loading="lazy"
                        src={noDataUndraw}
                        className="max-w-full mt-4 w-[120px]"
                    />
                    <div className="self-stretch mt-4 text-sm leading-5 text-center max-md:mx-1">
                        There is no team information available at the moment.
                    </div>
                </>
            )}
            <Dialog
                onOpenChange={(va) => {
                    setIsOpen(va);
                }}
                open={isOpen}
            >
                <DialogTrigger asChild>
                    <Button
                        onClick={() => {
                            setIsOpen(true);
                        }}
                        type="button"
                        variant={"outline"}
                        className="flex gap-2 justify-center mt-4 px-4 py-2 text-base font-medium leading-6 border border-gray-400 border-solid rounded-[45px]"
                    >
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54fc1e01636e7295a5352869767576129386d21f19d7b17803ead42955bf3f4f?apiKey=2863e55464904361bde551202a8063a4&"
                            className="shrink-0 my-auto w-5 aspect-square"
                        />
                        <div>Add Awards</div>
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="text-2xl">
                            Awards Details
                        </DialogTitle>
                    </DialogHeader>
                    <div>
                        <Input
                            className="w-full"
                            placeholder="Title"
                            {...register("award_title")}
                            required
                        />
                        {errors.award_title && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                {errors.award_title?.message}
                            </p>
                        )}
                    </div>
                    <div>
                        <div className="flex space-x-2 w-full justify-start items-center">
                            <p className="m-0">Award date:</p>
                            <Input
                                className="flex w-full"
                                {...register("award_date")}
                                type="date"
                                max={maxDate()}
                                required
                                onChange={handleAwardDateChange}
                            />
                        </div>
                        {errors.award_date && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {errors.award_date?.message}
                            </p>
                        )}
                    </div>
                    <div>
                        <Textarea
                            className="w-full rounded-[18px]"
                            placeholder="Description"
                            {...register("award_description")}
                            maxLength="255"
                        />
                        {errors.award_description && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs ml-1 mt-2">
                                {errors.award_description?.message}
                            </p>
                        )}
                    </div>{" "}
                    <div>
                        <div className="flex items-center border rounded-[18px] py-[11px] px-[16px] w-full">
                            <div className="flex-1">
                                <p className="m-0 text-black text-md">
                                    Add Photo
                                </p>
                                <p className="m-0 text-sm">
                                    Recommended size: 600px x 600px
                                </p>
                                {watch("award_image") !== null &&
                                    watch("award_image") !== undefined && (
                                        <Button
                                            type="button"
                                            className=" hover:cursor-none m-0 p-0"
                                            variant={"ghost"}
                                        >
                                            <p className="text-black m-0 text-xs w-32 truncate">
                                                {watch("award_image")?.name}
                                            </p>
                                        </Button>
                                    )}
                            </div>
                            <Button
                                className="bg-[#ACADBA] rounded-lg p-2"
                                type="button"
                                onClick={() => {
                                    handleButtonClick();
                                }}
                            >
                                <ArrowUpTrayIcon className="w-6 text-white" />
                            </Button>{" "}
                            <input
                                className="hidden"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </div>{" "}
                        {fileUploadError && (
                            <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {fileUploadError}
                            </p>
                        )}
                        {fileUploadError == null && errors.award_image && (
                            <p className="text-red-500 mt-2 text-left flex justify-start w-full m-0 text-xs ml-1">
                                {errors.award_image?.message}
                            </p>
                        )}
                    </div>
                    <Button
                        // onClick={() => {
                        //     setIsDoneAddingTime((prev) => prev + 1);
                        // }}
                        disabled={isLoading}
                        type="submit"
                        form="hook-form"
                        className=" rounded-3xl mt-8 w-full"
                    >
                        Add Award
                        {isLoading && (
                            <span className="ml-2">
                                <Loader2 className="h-5 animate-spin" />
                            </span>
                        )}{" "}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CompanyInformation;
