import { create } from "zustand";

const useAuthProcess = create((set) => ({
    index: 0,
    increment: () => set((state) => ({ index: state.index + 1 })),
    decrement: () => set((state) => ({ index: state.index - 1 })),
    reset: () => set({ bears: 0 }),
    setIndex: (index) => set({ index: index }),
}));

export default useAuthProcess;
