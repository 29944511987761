import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import ContactForm from "../components/ContactForm";
import ReplyDialog from "./ReplyDialog"; // Adjust the path as per your project structure

const Ticket = () => {
    const [tickets, setTickets] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showReplyDialog, setShowReplyDialog] = useState(false);
    const [refetchTickets, setRefetchTickets] = useState(0);

    const [selectedUuid, setSelectedUuid] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const uuid = queryParams.get("id");
        if (uuid || selectedUuid) {
            console.log("refetch");
            fetchTicket(uuid !== null ? uuid : selectedUuid);
        } else {
            fetchUserTickets();
        }
    }, [refetchTickets]);

    const fetchTicket = async (uuid) => {
        try {
            const response = await axios.get(`/ticket/data/${uuid}`); // Replace with your API endpoint
            setTicket(response.data); // Assuming response.data is your ticket object
        } catch (error) {
            console.error("Error fetching ticket:", error);
        }
    };

    const fetchUserTickets = async () => {
        console.log("NOW REFETCHING NEW TICKETS");
        try {
            const response = await axios.get(`/tickets`); // Replace with your API endpoint
            console.log(response);
            setTickets(response.data.tickets);
        } catch (error) {
            console.error("Error fetching user tickets:", error);
        }
    };

    if (ticket) {
        return (
            <div className="min-h-screen bg-gray-100 py-24 sm:py-6">
                <div className="max-w-2xl mx-auto px-4 mt-6">
                    <div className="flex space-x-2">
                        <h1 className="text-2xl font-bold">Ticket</h1>
                    </div>
                    <div className="bg-white shadow-md rounded-lg px-6 py-4 mb-6">
                        <h2 className="text-2xl font-bold mb-4">
                            {ticket.subject}
                        </h2>
                        <p className="text-sm text-gray-600 mb-2">
                            <strong>Date:</strong>{" "}
                            {new Date(ticket.created_at).toLocaleString()}
                        </p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg  px-6 py-4">
                        <h2 className="text-lg font-semibold mb-3">
                            Ticket History
                        </h2>
                        {ticket.history.map((historyItem, index) => (
                            <div
                                className="bg-gray-100 rounded-lg p-4 mb-3"
                                key={index}
                            >
                                <p className="text-sm mb-1">
                                    {historyItem.message}
                                </p>
                                <p className="text-xs text-gray-600">
                                    <strong>Date:</strong>{" "}
                                    {new Date(
                                        historyItem.created_at
                                    ).toLocaleString()}
                                </p>
                                {historyItem.attachments.length !== 0 && (
                                    <div className="mt-2">
                                        <strong>Attachments:</strong>
                                        <ul className="list-disc list-inside">
                                            {historyItem.attachments.map(
                                                (attachment, idx) => (
                                                    <li key={idx}>
                                                        <a
                                                            href={
                                                                attachment.url
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {attachment.name}
                                                        </a>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex justify-end mt-4">
                        <Button onClick={() => setShowReplyDialog(true)}>
                            Reply
                        </Button>
                    </div>
                </div>
                <ReplyDialog
                    open={showReplyDialog}
                    onClose={() => {
                        setShowReplyDialog(false);
                    }}
                    ticketId={ticket.id}
                    uuid={ticket.uuid}
                    hasSavedReply={(hasSaved) => {
                        if (hasSaved) {
                            setRefetchTickets((prev) => prev + 1);
                        }
                    }}
                />
            </div>
        );
    }

    if (!tickets) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Loader2
                    className={"my-28 h-16 w-16 text-black animate-spin"}
                />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 py-20 lg:py-6">
            <ContactForm
                showContactForm={showContactForm}
                setShowContactForm={setShowContactForm}
                hasSavedNewTicket={(hasSaved) => {
                    if (hasSaved) {
                        setRefetchTickets(refetchTickets + 1);
                    }
                }}
            />
            <div className="max-w-2xl mx-auto px-4 mt-6">
                <div className="w-full justify-between flex items-center mb-8 h-min">
                    <h1 className="text-2xl font-bold">Tickets</h1>
                    <Button
                        onClick={() => {
                            setShowContactForm(true);
                        }}
                    >
                        New Ticket
                    </Button>
                </div>
                <div className="h-[calc(100vh-180px)] sm:h-[calc(100vh-24px)] lg:h-[calc(100vh-210px)] overflow-y-auto">
                    {tickets.length === 0 ? (
                        <p>No tickets submitted.</p>
                    ) : (
                        tickets.map((ticket) => (
                            <div
                                key={ticket.id}
                                className="bg-white shadow-md rounded-lg px-6 py-4 mb-6"
                            >
                                <h2 className="text-xl font-semibold">
                                    {ticket.subject}
                                </h2>
                                <p className="text-sm text-gray-600 mb-2">
                                    <strong>Date:</strong>{" "}
                                    {new Date(
                                        ticket.created_at
                                    ).toLocaleString()}
                                </p>
                                <Button
                                    onClick={() => {
                                        setSelectedUuid(ticket.uuid);
                                        fetchTicket(ticket.uuid);
                                    }}
                                >
                                    View
                                </Button>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default Ticket;
