import React from "react";
import CompanyInformation from "./CompanyInformation";
import BusinessInformation from "./BusinessInformation";
import { Button } from "@/components/ui/button";
import useAuthProcess from "../../../zustand/authProcessIndex.js";
import { cn } from "../../../@/lib/utils.js";
import {
    ArrowRightStartOnRectangleIcon,
    EnvelopeIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import ContactForm from "../components/ContactForm";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const Onboarding = () => {
    const index = useAuthProcess((state) => state.index);
    const steps = [<BusinessInformation />, <CompanyInformation />];
    const setIndex = useAuthProcess((state) => state.setIndex);
    const [showAlert, setShowAlert] = React.useState(false);
    const [showContactForm, setShowContactForm] = React.useState(false);

    React.useEffect(() => {
        setIndex(0);
    }, []);

    const signOut = async () => {
        try {
            const res = await axios.post("/logout");
            console.log(res);
            if (res.status === 200) {
                window.location.href = "/login";
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div
            className={cn(
                "min-h-screen relative w-screen flex flex-col items-center justify-center",
                index == 0 && "py-[24px]"
            )}
        >
            <ContactForm
                showContactForm={showContactForm}
                setShowContactForm={setShowContactForm}
            />
            <AlertDialog
                open={showAlert}
                onOpenChange={(val) => {
                    setShowAlert(val);
                }}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle className="flex text-left">
                            Are your sure you want to sign out?
                        </AlertDialogTitle>
                    </AlertDialogHeader>
                    <AlertDialogFooter className="flex flex-row items-center justify-end w-full">
                        <AlertDialogCancel className="m-0 mr-2">
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            className="m-0"
                            onClick={() => signOut()}
                        >
                            Yes
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <div className="hidden space-x-2 sm:flex w-full absolute justify-end items-start h-max top-8 right-8">
                <Button
                    type="button"
                    variant={"outline"}
                    onClick={() => {
                        setShowContactForm(true);
                    }}
                >
                    Help
                    <span className="ml-2">
                        <EnvelopeIcon className="h-5" />
                    </span>
                </Button>
                <Button
                    onClick={() => {
                        setShowAlert(true);
                    }}
                    variant={"outline"}
                >
                    Logout{" "}
                    <span className="ml-2">
                        <ArrowRightStartOnRectangleIcon className={"h-5"} />
                    </span>
                </Button>
            </div>
            {/* <Toaster /> */}
            {steps[index]}
        </div>
    );
};

export default Onboarding;
