import React, { useState, useEffect } from "react";
import axios from "axios";

const Events = () => {
    const [events, setEvents] = useState(null);
    const [selectedSortBy, setSelectedSortBy] = useState("");
    const [params, setParams] = useState(
        new URLSearchParams(window.location.search).toString()
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/api/events?${params}`, {
                    headers: {
                        "Accept-Language": window.locale,
                    },
                });
                setEvents(response.data);
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        fetchData();
    }, [params]);

    const handleSortChange = (e) => {
        const sortByValue = e.target.value;
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("sortBy", sortByValue);
        const newParams = urlParams.toString();
        history.pushState({}, null, `events?${newParams}`);
        setParams(newParams);
    };

    const handlePageClick = (page) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("page", page);
        const newParams = urlParams.toString();
        history.pushState({}, null, `events?${newParams}`);
        setParams(newParams);
    };

    const pagination = () => {
        if (!events || !events.data || !events.data.pagination) return [];
        const currentPage = events.data.pagination.currentPage;
        const totalPages = events.data.pagination.totalPages;
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        return pages;
    };

    const renderEvents = () => {
        if (!events || !events.data || !events.data.events) return null;
        return events.data.events.map((event, index) => (
            <div key={index} className="col-xl-6">
                <div className="upcoming-item">
                    <div className="upcoming-item__date">
                        <span>{event.start_day}</span>
                        <span>{`${event.start_month},${event.start_year}`}</span>
                    </div>
                    <div className="upcoming-item__body">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-xl-12 mb-4">
                                <a href={`events/${event.slug}`}>
                                    <div className="upcoming-item__img">
                                        <img
                                            className=""
                                            src={event.image}
                                            alt={event.title}
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-12 col-xl-12">
                                <div className="upcoming-item__description">
                                    <h6 className="upcoming-item__title">
                                        <a href={`events/${event.slug}`}>
                                            {event.title.en}
                                        </a>
                                    </h6>
                                    <p
                                        className="startup-card-description"
                                        dangerouslySetInnerHTML={{
                                            __html: event.description.en,
                                        }}
                                    ></p>
                                    <div className="upcoming-item__details">
                                        <p>
                                            <svg className="icon">
                                                <use xlinkHref="#clock"></use>
                                            </svg>
                                            <strong>
                                                {event.date_equal
                                                    ? `${event.start_month} ${event.start_day} ${event.start_year}`
                                                    : `${event.start_month} ${event.start_day} ${event.start_year}, ${event.end_month} ${event.end_day} ${event.end_year}`}
                                            </strong>
                                        </p>
                                        <p>
                                            <svg className="icon">
                                                <use xlinkHref="#placeholder"></use>
                                            </svg>
                                            <strong>{event.location}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    const renderPagination = () => {
        if (
            !events ||
            !events.data ||
            !events.data.pagination ||
            events.data.pagination.totalPages <= 1
        )
            return null;
        const { currentPage, totalPages } = events.data.pagination;
        return (
            <nav className="pt-6">
                <ul className="pagination">
                    {currentPage > 1 && (
                        <li
                            onClick={() => handlePageClick(currentPage - 1)}
                            className="pagination__item pagination__item--prev"
                        >
                            <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                            ></i>
                            <span>Back</span>
                        </li>
                    )}
                    {pagination().map((item) => (
                        <li
                            key={item}
                            onClick={() => handlePageClick(item)}
                            className={`pagination__item ${
                                currentPage === item
                                    ? "pagination__item--active"
                                    : ""
                            }`}
                        >
                            <span>{item}</span>
                        </li>
                    ))}
                    {currentPage < totalPages && (
                        <li
                            onClick={() => handlePageClick(currentPage + 1)}
                            className="pagination__item pagination__item--next"
                        >
                            <span>Next</span>
                            <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                            ></i>
                        </li>
                    )}
                </ul>
            </nav>
        );
    };

    return (
        <div>
            <section className="section p-0 zindex-9 mt-3 mb-3">
                <div className="container">
                    <div className="row m-0 p-3 border border-light-custom rounded">
                        <div className="col-md-2 pl-0">
                            <select
                                value={selectedSortBy}
                                onChange={handleSortChange}
                                className="form__field px-3 sortby"
                                id="inputGroupSelect01"
                                name="sortby"
                            >
                                <option value="">Sort by</option>
                                <option value="newest">Newest</option>
                                <option value="oldest">Oldest</option>
                            </select>
                        </div>

                        <div className="col-md-3 pl-0">
                            <form action="/events">
                                <input
                                    type="text"
                                    defaultValue={
                                        new URLSearchParams(
                                            window.location.search
                                        ).get("search") || ""
                                    }
                                    name="search"
                                    className="form__field"
                                    placeholder="Search for"
                                />
                            </form>
                        </div>

                        <div className="col-sm-5">
                            <div className="d-flex">
                                <a
                                    className={`button button--primary mr-2 ${
                                        new URLSearchParams(
                                            window.location.search
                                        ).get("filter") === "upcoming"
                                            ? "active"
                                            : ""
                                    }`}
                                    href="/events?filter=upcoming"
                                >
                                    Upcoming Events
                                </a>
                                <a
                                    className={`button button--primary ${
                                        new URLSearchParams(
                                            window.location.search
                                        ).get("filter") === "past"
                                            ? "active"
                                            : ""
                                    }`}
                                    href="/events?filter=past"
                                >
                                    Past Events
                                </a>
                            </div>
                        </div>

                        <div className="col-md-2 d-flex align-items-center justify-content-end">
                            <p className="mb-0 form__title">
                                <span>
                                    {events &&
                                    events.data &&
                                    events.data.pagination
                                        ? events.data.pagination.total
                                        : 0}
                                </span>{" "}
                                Events
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section events-inner">
                <div className="w-90 d-flex justify-content-center flex-column align-items-center m-auto">
                    <div className="row offset-30">{renderEvents()}</div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">{renderPagination()}</div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Events;
