import React from "react";
import logo from "../../../../website_theme/img/Layer_1-2.png";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import logo2 from "../../../../website_theme/img/logo-2.png";
import { Input } from "@/components/ui/input";
import {
    UserIcon,
    EnvelopeIcon,
    EyeIcon,
    EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const registrationSchema = yup
    .object({
        name: yup
            .string()
            .required("Name is required")
            .test("not-only-spaces", "Name is invalid", (value) => {
                return /\S/.test(value);
            })
            .test(
                "no-leading-whitespace",
                "Name should not start with whitespace",
                (value) => {
                    return !/^\s/.test(value);
                }
            )
            .min(3, "Name must be at least 3 characters")
            .matches(
                /^[a-zA-Z ]+$/,
                "Name can only contain letters and spaces"
            ),
        email: yup
            .string()
            .email("Email Address is invalid")
            .required("Email is required"),
        type: yup.string().required("Name is required"),
        password: yup
            .string()
            .min(8, "Password must be at least 8 characters")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character."
            ),
    })
    .required();

const RegistrationProcess = () => {
    const [stepIndex, setStepIndex] = React.useState(0);
    const [showAlert, setShowAlert] = React.useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(registrationSchema),
    });

    const [obscureText, setObscureText] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    const registerUser = async (data) => {
        setIsLoading(true);
        try {
            const res = await axios.post("/register", data);
            console.log(res);
            moveToVerification(1);
            window.location.href = "/user-dashboard";
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            if (error.response?.data?.message === "Email unique") {
                setShowAlert(true);
            } else {
                window.location.href = "/user-dashboard";
            }
        }
    };

    const onSubmit = (data) => registerUser(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <AlertDialog
                open={showAlert}
                onOpenChange={(val) => {
                    setShowAlert(val);
                    reset({
                        name: "",
                        email: "",
                        password: "",
                    });
                }}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            Email is already taken
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            We found the same email registered. Do you want to
                            sign in?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter className="flex flex-row items-center justify-end w-full">
                        <AlertDialogCancel className="m-0 mr-2">
                            Cancel
                        </AlertDialogCancel>
                        <a href="/login" className="no-underline">
                            <AlertDialogAction >
                                Yes
                            </AlertDialogAction>
                        </a>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <div className="h-full flex flex-col w-full justify-center items-center">
                {stepIndex == 0 ? (
                    <>
                        <img
                            className=" header-logo__img logo--dark"
                            src={logo}
                            alt="logo"
                        />
                        <h1 className="text-5xl mt-8 font-semibold w-52 text-center">
                            Welcome to Start
                        </h1>
                        <p className="w-[368px] text-center text-sm mt-2 font-normal">
                            Experience the benefits of comprehensive funding
                            solutions led by industry pioneers.
                        </p>
                        <p className="font-semibold text-2xl mt-8 text-black">
                            Choose Account Type
                        </p>
                        <RadioGroup
                            defaultValue="startup"
                            className="w-[368px] space-y-1"
                            onValueChange={(val) => {
                                console.log(val);
                                setValue("type", val);
                            }}
                            {...register("type", { required: true })}
                        >
                            <div className="flex border-[#ACADBA] items-center space-x-2 border rounded-2xl w-full h-[36px] p-2">
                                <RadioGroupItem
                                    value="startup"
                                    id="option-one"
                                    className="border-1 border-[#ACADBA]"
                                />
                                <Label
                                    className="my-0 font-normal"
                                    htmlFor="option-one"
                                >
                                    Startup
                                </Label>
                            </div>
                            <div className="flex border-[#ACADBA] items-center space-x-2 border rounded-2xl w-full h-[36px] p-2">
                                <RadioGroupItem
                                    value="partner"
                                    id="option-two"
                                    className="border-1 border-[#ACADBA]"
                                />
                                <Label
                                    className="my-0 font-normal"
                                    htmlFor="option-two"
                                >
                                    Partner
                                </Label>
                            </div>
                            {/* <div className="flex border-[#ACADBA] items-center space-x-2 border rounded-2xl w-full h-[36px] p-2">
                                <RadioGroupItem
                                    value="talent"
                                    id="option-three"
                                    className="border-1 border-[#ACADBA]"
                                />
                                <Label
                                    className="my-0 font-normal"
                                    htmlFor="option-three"
                                >
                                    Talent
                                </Label>
                            </div> */}
                        </RadioGroup>
                        {errors.type && (
                            <p className="text-red-500 text-xs mt-2">
                                Account type is required
                            </p>
                        )}
                        <Button
                            onClick={() => {
                                if (!errors.type) {
                                    setStepIndex(stepIndex + 1);
                                }
                            }}
                            className="font-medium text-[16px] rounded-3xl mt-8 w-[368px]"
                        >
                            Next
                        </Button>
                    </>
                ) : (
                    <div className="h-full w-full flex flex-col items-center justify-center">
                        <img
                            className="w-[120px] h-[68.56px]"
                            src={logo2}
                            alt="logo"
                        />
                        <div className="flex h-max items-center mt-[32px] justify-center space-x-2">
                            <div className="h-[2px] w-[94px] bg-[#00833E]" />
                            <div className="h-[2px] w-[94px] bg-[#0000001A]/10" />
                        </div>
                        <p className="font-semibold text-2xl mt-[66px] text-black">
                            Personal Information
                        </p>
                        <div className="space-y-[8px] w-[369px]">
                            <Input
                                placeholder="Full name"
                                type="text"
                                className="w-full rounded-full"
                                icon={UserIcon}
                                {...register("name", {
                                    required: true,
                                })}
                            />
                            {errors.name && (
                                <p className="text-red-500 text-xs mt-2 ml-1">
                                    {errors.name?.message}
                                </p>
                            )}
                            <Input
                                placeholder="Email"
                                className="w-full rounded-full"
                                icon={EnvelopeIcon}
                                {...register("email", {
                                    required: true,
                                    minLength: 1,
                                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    maxLength: 25,
                                })}
                            />
                            {errors.email && (
                                <p className="text-red-500 text-xs mt-2 ml-1">
                                    {errors.email?.message}
                                </p>
                            )}
                            <Input
                                placeholder="Password"
                                type={obscureText ? "password" : "text"}
                                className="w-full rounded-full"
                                icon={obscureText ? EyeIcon : EyeSlashIcon}
                                onIconClick={() => {
                                    setObscureText(!obscureText);
                                }}
                                {...register("password", {
                                    required: true,
                                    minLength: 8,
                                })}
                            />
                            {errors.password && (
                                <p className="text-red-500 m-0 text-xs mt-2 ml-1">
                                    {errors.password?.message}
                                </p>
                            )}
                        </div>
                        <Button
                            type="submit"
                            className="font-medium text-[16px] rounded-3xl mt-8 w-[368px]"
                            disabled={isLoading}
                        >
                            Next
                            {isLoading && (
                                <span className="ml-2">
                                    <Loader2 className="h-5 animate-spin" />
                                </span>
                            )}{" "}
                        </Button>
                    </div>
                )}
            </div>
        </form>
    );
};

export default RegistrationProcess;
