import React from "react";
import logo from "../../../../website_theme/img/Layer_1-2.png";
import axios from "axios";

const EmailVerification = ({ email }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const signOut = async () => {
        try {
            const res = await axios.post("/logout");
            console.log(res);
            if (res.status === 200) {
                window.location.href = "/login";
            }
        } catch (error) {
            console.log(error);
        }
    };

    const resendEmailVerification = async () => {
        setIsLoading(true);
        try {
            const res = await axios.post("/email/verification-notification");
            console.log(res.data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    function maskEmail(email) {
        // Split the email into username and domain parts
        const [username, domain] = email.split("@");

        // Get the first two characters of the username
        const maskedUsername = username.substring(0, 2);

        // Mask the rest of the characters in the username with asterisks
        const masked = maskedUsername + "*".repeat(username.length - 2);

        // Concatenate the masked username with the domain
        return masked + "@" + domain;
    }

    return (
        <div className="min-h-screen w-full flex flex-col items-center justify-center">
            <img
                className="header-logo__img logo--dark"
                src={logo}
                alt="logo"
            />
            <h1 className="text-5xl mt-8 font-semibold w-64 text-center">
                An email is on the way
            </h1>
            <p className="w-[368px] text-center text-sm mt-2">
                A email verification link has been sent to {maskEmail(email)}
            </p>
            <p className="w-[368px] text-center  text-[#20212B] text-sm mt-[48px]">
                Did not receive it?{" "}
                <button
                    disabled={isLoading}
                    onClick={resendEmailVerification}
                    className="font-bold hover:text-black/80"
                >
                    Resend Link
                </button>
            </p>
            <button
                disabled={isLoading}
                onClick={signOut}
                className="text-xs hover:underline"
            >
                Sign out
            </button>
        </div>
    );
};

export default EmailVerification;
