import * as React from "react";
import { cn } from "@/lib/utils";

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
    return (
        <div className="relative h-max">
            <input
                type={type}
                className={cn(
                    "flex h-10 w-full border border-input items-center rounded-full placeholder:font-normal pr-[12px] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ring-offset-background bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed disabled:opacity-50",
                    className
                )}
                ref={ref}
                {...props}
            />
            {props.icon && (
                <div className="absolute right-6 top-1/2 transform bg-white -translate-y-1/2">
                    <button
                        type="button"
                        tabIndex="-1"
                        onClick={() => {
                            if (props.onIconClick) {
                                props.onIconClick();
                            }
                        }}
                        className="h-[20px] flex justify-center items-center"
                    >
                        <props.icon className="h-[20px] flex justify-center items-center" />
                    </button>
                </div>
            )}
        </div>
    );
});
Input.displayName = "Input";

export { Input };
