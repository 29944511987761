import React, { useEffect, useState, useRef } from "react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog";
import { Loader2 } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import axios from "axios";
import { useToast } from "@/components/ui/use-toast";

const ContactForm = ({
    showContactForm,
    setShowContactForm,
    hasSavedNewTicket,
}) => {
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [nameError, setNameError] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [bodyError, setBodyError] = useState("");
    const { toast } = useToast();
    const [userData, setUserData] = useState(null);

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const subjectRef = useRef(null);
    const bodyRef = useRef(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get("user"); // Replace with your API endpoint
                setUserData(response.data); // Assuming response.data is your ticket object
                if (
                    email.length === 0 &&
                    name.length === 0 &&
                    phone.length === 0
                ) {
                    setEmail(response.data.email);
                    setName(response.data.name);
                    setPhone(response.data?.phone_number ?? "");
                }
            } catch (error) {
                console.error("Error fetching user:", error);
            }
        };
        fetchUser();
    }, [email, phone, name]);

    // useEffect(() => {
    //     if (userData) {
    //         if (phoneRef.current && userData.phone_number == null) {
    //             phoneRef.current.focus();
    //         } else {
    //             if (subjectRef.current) {
    //                 subjectRef.current.focus();
    //             }
    //         }
    //     }
    // }, [userData, phoneRef, subjectRef]);

    const sendEmail = async () => {
        setIsLoading(true);
        const data = {
            name,
            subject,
            body,
            email,
            phone,
        };
        try {
            const uuid = await axios.post("/messages", {
                ...data,
                message: body,
            });
            const res = await axios.post("/send-contact-form", {
                ...data,
                uuid: uuid.data.uuid,
            });
            if (res.status === 200) {
                toast({
                    title: "Sent! ✅",
                });
                setName("");
                setEmail("");
                setSubject("");
                setBody("");
                setPhone("");
            }
            if (hasSavedNewTicket) {
                hasSavedNewTicket(true);
            }
            setShowContactForm(false);
        } catch (error) {
            if (hasSavedNewTicket) {
                hasSavedNewTicket(false);
            }
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setEmailError("Invalid email address");
            emailRef.current.focus();
            return;
        }
        if (!validatePhone(phone) && phone.length !== 0) {
            setPhoneError("Invalid phone number");
            phoneRef.current.focus();
            return;
        }
        if (isOnlySpaces(name)) {
            setNameError("Name cannot be only spaces");
            nameRef.current.focus();
            return;
        }
        if (isOnlySpaces(subject)) {
            setSubjectError("Subject cannot be only spaces");
            subjectRef.current.focus();
            return;
        }
        if (isOnlySpaces(body)) {
            setBodyError("Message cannot be only spaces");
            bodyRef.current.focus();
            return;
        }
        setEmailError("");
        setPhoneError("");
        setNameError("");
        setSubjectError("");
        setBodyError("");
        sendEmail();
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[\d+]+$/;
        if (!phoneRegex.test(phone)) {
            return false;
        }
        if (/^\++$/.test(phone)) {
            return false;
        }
        const validPhoneRegex = /^(\+\d+)?\d+$/;
        return validPhoneRegex.test(phone);
    };

    const isOnlySpaces = (input) => {
        return input.trim().length === 0;
    };

    return (
        <Dialog
            open={showContactForm}
            onOpenChange={(val) => {
                if (!val) {
                    setName("");
                    setEmail("");
                    setSubject("");
                    setPhone("");
                    setBody("");
                    setEmailError("");
                    setPhoneError("");
                    setNameError("");
                    setSubjectError("");
                    setBodyError("");
                }
                setShowContactForm(val);
            }}
        >
            <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
                <DialogHeader>
                    <DialogTitle>New Ticket</DialogTitle>
                </DialogHeader>
                <form id="contact-form" onSubmit={handleSubmit}>
                    <div className="space-y-4 h-36 overflow-auto px-2 lg:h-full w-full">
                        <Input
                            readOnly={userData}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setNameError("");
                            }}
                            placeholder="Full name"
                            required
                            ref={nameRef}
                        />
                        {nameError && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                {nameError}
                            </p>
                        )}
                        <Input
                            readOnly={userData}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError("");
                            }}
                            type="email"
                            placeholder="Email"
                            required
                            ref={emailRef}
                        />
                        {emailError && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                {emailError}
                            </p>
                        )}
                        <Input
                            type="tel"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                setPhoneError("");
                            }}
                            placeholder="Phone"
                            ref={phoneRef}
                        />
                        {phoneError && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                {phoneError}
                            </p>
                        )}
                        <Input
                            onChange={(e) => {
                                setSubject(e.target.value);
                                setSubjectError("");
                            }}
                            value={subject}
                            placeholder="Subject"
                            required
                            ref={subjectRef}
                        />
                        {subjectError && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                {subjectError}
                            </p>
                        )}
                        <Textarea
                            onChange={(e) => {
                                setBody(e.target.value);
                                setBodyError("");
                            }}
                            value={body}
                            placeholder="Message"
                            required
                            maxLength="255"
                            ref={bodyRef}
                        />
                        {bodyError && (
                            <p className="text-red-500 text-left flex justify-start w-full m-0 text-xs mt-2 ml-1">
                                {bodyError}
                            </p>
                        )}
                        <DialogFooter>
                            <Button
                                disabled={isLoading}
                                form="contact-form"
                                type="submit"
                            >
                                Submit
                                {isLoading && (
                                    <span className="ml-2">
                                        <Loader2 className="h-5 animate-spin" />
                                    </span>
                                )}
                            </Button>
                        </DialogFooter>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ContactForm;
