import React, { useState } from "react";
import axios from "axios";
import {
    Dialog,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";

const ReplyDialog = ({ open, onClose, ticketId, uuid, hasSavedReply }) => {
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { toast } = useToast();

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const totalSize = selectedFiles.reduce(
            (acc, file) => acc + file.size,
            0
        );

        if (totalSize > 15 * 1024 * 1024) {
            // 15MB limit
            toast("Total file size should not exceed 15MB");
        } else {
            setFiles(selectedFiles);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("ticket_id", ticketId);
            formData.append("message", message);
            formData.append("uuid", uuid);
            console.log(files);
            files.forEach((file) => {
                formData.append("files[]", file);
            });

            const response = await axios.post(
                "/save-ticket-history",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            console.log("Message history saved:", response.data);
            setIsLoading(false);
            hasSavedReply(true);
            onClose(); // Close the dialog after successful submission
        } catch (error) {
            hasSavedReply(false);
            console.error("Error saving message history:", error);
            setIsLoading(false);
            // Handle error (show message, etc.)
        }
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <Toaster />
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Reply to Ticket</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded mb-4"
                        placeholder="Write your message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                    <input
                        type="file"
                        className="mb-4"
                        onChange={handleFileChange}
                        multiple
                        accept="image/*,application/pdf"
                    />
                    <DialogFooter>
                        <Button type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <Loader2 className="h-5 animate-spin" />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ReplyDialog;
