import "./bootstrap";

// Import React and ReactDOM
import React from "react";
import { createRoot } from "react-dom/client";

// Import your React components
import { Toaster } from "@/components/ui/toaster";
import Register from "./pages/auth/components/Register";
import Ticket from "./pages/auth/components/Ticket";
import EmailVerification from "./pages/auth/components/EmailVerification";
import Onboarding from "./pages/auth/components/Onboarding";
import Footer from "./pages/auth/components/Footer";
import Events from "./pages/auth/components/Events";
import Login from "./pages/auth/components/Login";
import MyAccount from "./pages/auth/components/authenticated/MyAccount";
import * as Sentry from "@sentry/react";

// Initialize Alpine.js
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

Sentry.init({
    dsn: "https://1cf18d7e7537b2f02bd5679db0760a47@o4507192612487168.ingest.us.sentry.io/4507192616943616",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (document.getElementById("verify-email")) {
    const emailVerification = createRoot(
        document.getElementById("verify-email")
    );
    const userEmail = document
        .getElementById("verify-email")
        .getAttribute("email");
    emailVerification.render(<EmailVerification email={userEmail} />);
}

if (document.getElementById("register")) {
    const register = createRoot(document.getElementById("register"));
    register.render(
        <div className="w-full h-full">
            <Register />
            <Toaster />
        </div>
    );
}

if (document.getElementById("onboarding")) {
    const onboarding = createRoot(document.getElementById("onboarding"));
    onboarding.render(
        <div className="w-full h-full">
            <Onboarding />
            <Toaster />
        </div>
    );
}

if (document.getElementById("my-account")) {
    const onboarding = createRoot(document.getElementById("my-account"));
    onboarding.render(
        <div className="w-screen h-full">
            <MyAccount />
            <Toaster />
        </div>
    );
}

if (document.getElementById("footer")) {
    const onboarding = createRoot(document.getElementById("footer"));
    onboarding.render(
        <div className="w-full h-full">
            <Footer />
            <Toaster />
        </div>
    );
}

if (document.getElementById("eventsPage")) {
    const events = createRoot(document.getElementById("eventsPage"));
    events.render(
        <div className="w-screen h-full">
            <Events />
            <Toaster />
        </div>
    );
}

if (document.getElementById("loginPage")) {
    const login = createRoot(document.getElementById("loginPage"));
    login.render(
        <div className="w-screen h-full">
            <Login />
            <Toaster />
        </div>
    );
}

if (document.getElementById("ticket")) {
    const ticket = createRoot(document.getElementById("ticket"));
    ticket.render(
        <div className="w-screen h-full">
            <Ticket />
            <Toaster />
        </div>
    );
}
