import create from "zustand";

// Create a Zustand store slice
const useBusinessInformationDataStore = create((set) => ({
    formData: {
        company_name: "",
        uae_business: "",
        city_id: "",
        country_id: "",
        company_address: "",
        company_phone: "",
        company_email: "",
        company_website: "",
        company_license: null, // Initial value is null for file input
        agreeToTerms: false,
    },
    companyLicense: null,
    setFormData: (newFormData) =>
        set((state) => ({ formData: { ...state.formData, ...newFormData } })),
    setCompanyLicense: (lic) => set((state) => ({ companyLicense: lic })),
}));

export default useBusinessInformationDataStore;
